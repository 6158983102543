// Function to dynamically add Google Font link to the <head> and wait until it's loaded
export function loadGoogleFont(fontUrl: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const link = document.createElement("link");
    link.href = fontUrl;
    link.rel = "stylesheet";
    link.onload = () => resolve();
    link.onerror = () => reject(new Error("Failed to load font"));
    document.head.appendChild(link);
  });
}

// Function to extract font-family name from the URL
export function extractFontFamily(url: string) {
  const match = url.match(/family=([^:&,]+)/);
  if (match) {
    return match[1].replace(/\+/g, " ");
  }
  return url;
}

// Apply the font to all text elements, including html and body
export async function applyFontFromConfig(fontUrl: string) {
  try {
    await loadGoogleFont(fontUrl);
    const fontFamily = extractFontFamily(fontUrl);

    const textElements = document.querySelectorAll(
      "html, body, span, p, h1, h2, h3, h4, h5, h6, a, li, div, button, input, textarea, select"
    );

    textElements.forEach((element) => {
      (element as HTMLElement).style.fontFamily = `${fontFamily}, sans-serif`;
    });

    document.documentElement.style.fontFamily = `${fontFamily}, sans-serif`;
    document.body.style.fontFamily = `${fontFamily}, sans-serif`;
  } catch (error) {
    console.error("Failed to load the font:", error);
  }
}
