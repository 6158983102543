export function getLocalAndParse(key: string) {
  const stringVal = localStorage.getItem(key);
  let jsonVal = undefined;

  if (stringVal === "undefined" || stringVal === null) {
    return undefined;
  } else {
    jsonVal = JSON.parse(stringVal);
    if (typeof jsonVal === "string") return JSON.parse(jsonVal);
    return jsonVal;
  }
}

export function setLocalStore(key: string, value: string) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function getLocalSessionAndParse(key: string) {
  const stringVal = sessionStorage.getItem(key);
  if (stringVal === "undefined" || stringVal === null) {
    return false;
  } else {
    return JSON.parse(stringVal);
  }
}

export function setSessionStore(key: string, value: string) {
  sessionStorage.setItem(key, JSON.stringify(value));
}

export function removeLocalStore(key: string) {
  localStorage.removeItem(key);
}
