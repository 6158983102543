import { FC, useState } from "react";
import { useMediaQuery } from "react-responsive";

import Stepper from "../molecules/Stepper/Stepper";
import BellyChoice from "../BellyChoice";
import MeasuredBy from "../molecules/MeasuredBy";

import { GENDERS, MODAL_STEPS } from "../../constants/modal";

import { useTranslation } from "react-i18next";

import torse_1_female from "../../assets/questionnaire/torse/torse-1-female.svg";
import torse_2_female from "../../assets/questionnaire/torse/torse-2-female.svg";
import torse_3_female from "../../assets/questionnaire/torse/torse-3-female.svg";

import torse_1_male from "../../assets/questionnaire/torse/torse-1-male.svg";
import torse_2_male from "../../assets/questionnaire/torse/torse-2-male.svg";
import torse_3_male from "../../assets/questionnaire/torse/torse-3-male.svg";

import PhotoCameraIcon from "../../assets/icons/PhotoCameraIcon";

import {
  titleStyles,
  disabledStylesContinue,
  activeStylesContinue,
  scanCTAStyle,
  stepperStyle,
  questionTwoScreenUI,
} from "../../configs";
import { capitalizeFirstLetter, isEmptyCTAStyle } from "../../utils";
import { newMeasure } from "../../api/endpoints";

import "./index.css";

interface IPropsStepTorse {
  gender: string;
  nextStep: () => void;
  setStep: (
    value: React.SetStateAction<{
      number: number;
    }>
  ) => void;
  setPreviousStep: React.Dispatch<
    React.SetStateAction<{
      number: number;
    } | null>
  >;
  value: {
    current: number;
  };
  subtitleStyles: {
    color: React.CSSProperties["color"];
    fontSize: React.CSSProperties["fontSize"];
    fontWeight: React.CSSProperties["fontWeight"];
    textAlign: React.CSSProperties["textAlign"];
    textTransform: React.CSSProperties["textTransform"];
    justifyContent: React.CSSProperties["justifyContent"];
  };
}

const StepTorse: FC<IPropsStepTorse> = ({
  gender,
  nextStep,
  setStep,
  setPreviousStep,
  value,
  subtitleStyles,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [selected, setSelected] = useState<number | undefined>(value.current);

  const { t } = useTranslation("components/torso");

  const scanTextTransform =
    scanCTAStyle.textTransform as React.CSSProperties["textTransform"];
  const continueButtonStyles = !selected
    ? disabledStylesContinue
    : activeStylesContinue;

  return (
    <>
      <div
        className="belly-description"
        style={{
          ...subtitleStyles,
          textAlign: titleStyles.textAlign as React.CSSProperties["textAlign"],
          justifyContent:
            titleStyles.textAlign as React.CSSProperties["justifyContent"],
          color: titleStyles.color as React.CSSProperties["color"],
          textTransform:
            subtitleStyles.textTransform === "capitalize"
              ? "none"
              : (subtitleStyles.textTransform as React.CSSProperties["textTransform"]),
        }}
      >
        <p>
          {subtitleStyles.textTransform === "capitalize"
            ? capitalizeFirstLetter(t("description"))
            : t("description")}
        </p>
      </div>
      <div
        className="belly"
        style={{
          marginTop: isMobile ? "20px" : questionTwoScreenUI.padding,
          gap: isMobile ? "10px" : questionTwoScreenUI.spacing,
        }}
      >
        <div
          className="body"
          // style={{
          //   gap: questionTwoScreenUI.gap,
          // }}
        >
          <BellyChoice
            image={gender === GENDERS.M ? torse_1_male : torse_1_female}
            onClick={() => {
              value.current = 1;
              setSelected(1);
            }}
            text={
              gender === GENDERS.M ? t("size.male.one") : t("size.female.one")
            }
            isSelected={selected === 1}
            gender={gender}
            type="torso"
          />
          <BellyChoice
            image={gender === GENDERS.M ? torse_2_male : torse_2_female}
            onClick={() => {
              value.current = 2;
              setSelected(2);
            }}
            text={
              gender === GENDERS.M ? t("size.male.two") : t("size.female.two")
            }
            isSelected={selected === 2}
            gender={gender}
            type="torso"
          />
          <BellyChoice
            image={gender === GENDERS.M ? torse_3_male : torse_3_female}
            onClick={() => {
              value.current = 3;
              setSelected(3);
            }}
            text={
              gender === GENDERS.M
                ? t("size.male.three")
                : t("size.female.three")
            }
            isSelected={selected === 3}
            gender={gender}
            type="torso"
          />
        </div>
        <div className="controls">
          {stepperStyle && (
            <Stepper
              stepsNum={gender === GENDERS.M ? 3 : 2}
              step={2}
              key={1}
              className="stepper"
            />
          )}
          <button
            type="button"
            disabled={!selected}
            className="continue-button step-belly-button"
            onClick={() => nextStep()}
            style={{
              ...continueButtonStyles,
              marginTop: questionTwoScreenUI.continueGap,
              textTransform:
                continueButtonStyles.textTransform === "capitalize"
                  ? "none"
                  : continueButtonStyles.textTransform,
            }}
          >
            {continueButtonStyles.textTransform === "capitalize"
              ? capitalizeFirstLetter(t("continue", { ns: "components/intro" }))
              : t("continue", { ns: "components/intro" })}
          </button>

          {!!scanCTAStyle && !isEmptyCTAStyle(scanCTAStyle) && (
            <div
              style={{
                display: "flex",
                border: `1px solid ${scanCTAStyle.borderColor}`,
                borderWidth: scanCTAStyle.borderWidth,
                borderRadius: scanCTAStyle.borderRadius,
                borderColor: scanCTAStyle.borderColor,
                fontSize: scanCTAStyle.fontSize,
                color: scanCTAStyle.fontColor,
                fontWeight: scanCTAStyle.fontWeight,
                textTransform:
                  scanTextTransform === "capitalize"
                    ? "none"
                    : scanTextTransform,
                marginTop: isMobile
                  ? `calc(${scanCTAStyle.spacingTop} - 20px)`
                  : scanCTAStyle.spacingTop,
                width: "100%",
                height: "auto",
                maxHeight: "48px",
                backgroundColor: "#FFF",
                cursor: "pointer",
                justifyContent: "center",
                padding: isMobile ? "11px 0" : "12px 0",
              }}
              className="scan-CTA-button"
              onClick={async () => {
                await newMeasure("scan");
                setStep(MODAL_STEPS.QR_CODE);
                setPreviousStep(MODAL_STEPS.TORSO);
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: scanCTAStyle.spacing,
                  textDecoration:
                    scanCTAStyle.fontStyle === "underline"
                      ? "none"
                      : scanCTAStyle.fontStyle,
                  alignItems: "center",
                }}
              >
                <PhotoCameraIcon fill={scanCTAStyle.fontColor} />
                <span
                  style={{
                    textAlign: isMobile ? "left" : "center",
                    fontSize: scanCTAStyle.fontSize,
                  }}
                  className={`${
                    scanCTAStyle.fontStyle === "underline"
                      ? "scan-text underline"
                      : ""
                  }`}
                >
                  {scanTextTransform === "capitalize"
                    ? capitalizeFirstLetter(
                        t("scan", { ns: "components/belly" })
                      )
                    : t("scan", { ns: "components/belly" })}
                </span>
              </div>
            </div>
          )}
        </div>
        <MeasuredBy />
      </div>
    </>
  );
};

export default StepTorse;
