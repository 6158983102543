import { FC } from "react";

import QRCode from "react-qr-code";
import Lottie from "lottie-react";

import { subtitlesStyles } from "../../configs";

import "./index.css";

interface IPropsQrcodeDesktop {
  url?: string;
  loading?: boolean;
}

const QrcodeDesktop: FC<IPropsQrcodeDesktop> = ({ url, loading }) => {
  return (
    <>
      {!loading ? (
        <QRCode
          className="qrcode"
          size={220}
          value={`${url}`}
          fgColor={subtitlesStyles.color}
          viewBox={"0 0 256 256"}
        />
      ) : (
        <Lottie
          className="qrcode_animation"
          animationData={require("../../assets/animations/qrcode_loader.json")}
        />
      )}
    </>
  );
};

export default QrcodeDesktop;
