import { i18n } from "../i18n";
import "../i18n";

export const initializeLanguage = () => {
  const allowedLanguages = ["fr", "en", "de", "ja", "it", "pt", "ko", "es"];
  const defaultLanguage = "en";
  const url = new URL(window.location.href);
  const params = new URLSearchParams(window.location.search);
  const lang = params.get("lang")?.split("-")[0].toLowerCase();

  const langRes = lang
    ? allowedLanguages.includes(lang)
      ? lang
      : defaultLanguage
    : defaultLanguage;

  if (!params.has("lang")) {
    params.append("lang", langRes);
    url.search = params.toString();
    window.history.replaceState({}, "", url.toString());
  }

  i18n.changeLanguage(langRes);

  return langRes;
};

export const convertHeightToFeetAndInches = (heightInCm: string) => {
  const heightStr = heightInCm.toString();

  // If the input length is less than 2, pad with leading zero
  const paddedHeightStr = heightStr.padStart(2, "0");

  // Extract first digit as feet and second digit as inches
  const feetValue = paddedHeightStr[0];
  const inchesValue = paddedHeightStr[1];

  return { feetValue, inchesValue };
};

export const convertFeetAndInchesToCm = (
  feetValue: string,
  inchesValue: string
) => {
  // Combine feet and inches into a single string
  const heightStr = `${feetValue}${inchesValue}`;

  return heightStr;
};

export const INCHtoCM = (v: any) => {
  const [feet, inches] = v.split(" ").map(Number);
  return (feet * 30.48 + inches * 2.54).toFixed(2).toString();
};

export const LBStoKG = (v: number) => (v * 0.453592).toFixed(2).toString();
