import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { brandsArray } from "./configs/configLoader";

const cleanDomain = (domain: string) => {
  const patternsToRemove = [".com", ".fr", "www."];
  return patternsToRemove.reduce(
    (result, pattern) => result.replace(pattern, ""),
    domain
  );
};

const urlParams = new URLSearchParams(window.location.search);
const requestDomain = urlParams.get("domain");

const getMainDomainFromRequest = (domain: string) => {
  for (const brand of brandsArray) {
    if (brand.domains.includes(domain)) {
      return cleanDomain(brand.domains[0]);
    }
  }
  return "default";
};

const brand = requestDomain
  ? getMainDomainFromRequest(requestDomain)
  : "default";

const namespaces = [
  "components/intro",
  "components/modal",
  "components/device_select",
  "components/qrcode/desktop",
  "components/qrcode/mobile",
  "components/belly",
  "components/torso",
  "components/cuisses",
  "components/breasts",
  "components/results/error",
  "components/results/result",
  "components/antiBracketing",
  "components/consentement",
];

export async function loadTranslations(lang: string) {
  const lng = lang || "fr";

  for (const ns of namespaces) {
    const defaultTranslationPath = `/locales/default/${lng}/${ns}.json`;
    const brandTranslationPath = `/locales/${brand}/${lng}/${ns}.json`;

    try {
      let finalTranslation = {};

      if (brand !== "default") {
        const brandResponse = await fetch(brandTranslationPath);
        if (brandResponse.ok) {
          const brandTranslation = await brandResponse.json();
          finalTranslation = brandTranslation;
        }
      }

      const defaultResponse = await fetch(defaultTranslationPath);
      if (defaultResponse.ok) {
        const defaultTranslation = await defaultResponse.json();
        finalTranslation = { ...defaultTranslation, ...finalTranslation };
      } else {
        console.warn(`Failed to load default translation for ${ns}`);
        return false;
      }

      i18n.addResourceBundle(lng, ns, finalTranslation, true, true);
    } catch (error) {
      // console.log(`Error ${ns}:`, error);
    }
  }
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    ns: namespaces,
    defaultNS: "components/modal",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `/locales/default/{{lng}}/{{ns}}.json`,
    },
    saveMissing: false,
  });

export { i18n };
