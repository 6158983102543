import { SvgIcon, SvgIconProps } from "@mui/material";

const CloseIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 0.5L11.5769 11.5" stroke="black" strokeLinecap="round" />
    <path d="M12 0.5L1.42308 11.5" stroke="black" strokeLinecap="round" />
  </SvgIcon>
);

export default CloseIcon;
