import { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

import DrawerSteps from "./components/DrawerSteps";

import {
  MODAL_STEPS,
  ERROR_EMPTY,
  GENDERS,
  VALIDATION_RULES,
  getModalStepsTitle,
} from "../../constants/modal";

import {
  capitalizeFirstLetter,
  convertFeetAndInchesToCm,
  convertHeightToFeetAndInches,
  INCHtoCM,
  initializeLanguage,
  LBStoKG,
} from "../../utils";
import { useUserContext } from "../../store/userContext";
import "../../i18n";
import {
  checkStocks,
  questionPushAnswers,
  recommend,
} from "../../api/endpoints";

import CloseIcon from "../../icons/CloseIcon";
import ArrowBackIcon from "../../icons/ArrowBackIcon";
import { Box, IconButton } from "@mui/material";

import {
  font,
  getQueryParam,
  resultScreenUI,
  titleStyles,
  uxConsentScreen,
  uxGender,
  uxRoute,
} from "../../configs";
import { loadTranslations } from "../../i18n";
import { ReducedResultType, SizeDataType } from "../../types/result";
import { removeLocalStore } from "../../store/localStoreUtils";
import { brandsArray } from "../../configs/configLoader";

import "./index.css";

const DrawerModal = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isSmallMobile = useMediaQuery({ maxWidth: 374 });

  const userContext = useUserContext() || undefined;

  const { t } = useTranslation("components/intro");

  const localGender = localStorage.getItem("gender") || "";

  const [selectedGender, setSelectedGender] = useState(
    !uxGender ? "" : uxGender === "male" ? GENDERS.M : GENDERS.F
  );

  const [selectedRoute, setSelectedRoute] = useState(uxRoute || "");
  const [MODAL_STEPS_TITLE, SET_MODAL_STEPS_TITLE] = useState<any | string>("");

  const [recommendedSize, setRecommendedSize] = useState<any>();
  const [reducedResult, setReducedResult] =
    useState<ReducedResultType | null>();
  const [needValidate, setNeedValidate] = useState(false);
  const [productStockData, setProductStockData] = useState<any | null>(null);
  const [similarProducts, setSimilarProducts] = useState<any | null>(null);
  const [isSizeUnavailable, setIsSizeUnavailable] = useState<boolean>(false);
  const [selectedSize, setSelectedSize] = useState<SizeDataType | null>(null);

  const [previousStep, setPreviousStep] = useState<{
    number: number;
  } | null>(null);

  const [unit, setUnit] = useState("cm");
  const [height, setHeight] = useState("");
  const [feet, setFeet] = useState("");
  const [inches, setInches] = useState("");

  const [weight, setWeight] = useState<string | undefined>("");
  const [unitWeight, setUnitWeight] = useState("kg");
  const [age, setAge] = useState("");

  const [step, setStep] = useState<any | undefined>();

  const [sizeCountry, setSizeCountry] = useState<string>("FR");

  const [disableContinue, setDisableContinue] = useState(false);
  const [error, setError] = useState(ERROR_EMPTY);

  const bellyValue = useRef(0);
  const torsoValue = useRef(0);
  const cuissesValue = useRef(0);
  const sizeValue = useRef("");
  const cupValue = useRef("");

  const urlParameters = new URLSearchParams(window.location.search);
  const domain = urlParameters.get("domain");

  const brandDefined = brandsArray.find((brand) =>
    domain ? brand.domains.includes(domain) : null
  );

  const mobileDefaultStep = isMobile ? MODAL_STEPS.GENDER : MODAL_STEPS.INTRO;
  const mobileGenderStep =
    isMobile && uxGender ? MODAL_STEPS.INTRO_MOBILE : mobileDefaultStep;
  const defaultStep = uxConsentScreen ? MODAL_STEPS.CONSENT : mobileGenderStep;

  useEffect(() => {
    const uid = localStorage.getItem("uid") || "";
    const mid = localStorage.getItem("mid") || "";

    if (uid) {
      if (mid && step?.number !== MODAL_STEPS.RESULT.number) {
        goToResultScreen();
      } else if (!recommendedSize?.length) {
        setStep(defaultStep);
        !uxGender && setSelectedGender("");
      }
    } else if (!uid && userContext) {
      userContext
        .newUser(domain)
        .then(() => {
          setStep(defaultStep);
          !uxGender && setSelectedGender("");
        })
        .catch((e: any) => console.log(e));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uxGender, defaultStep, domain, userContext, recommendedSize?.length]);

  useEffect(() => {
    const cachedDomain = localStorage.getItem("domain");
    const domain = getQueryParam("domain");

    if (cachedDomain !== domain && userContext) {
      removeLocalStore("uid");

      userContext
        .newUser(domain)
        // .then((e: any) => {
        //   console.log("user_id", e);
        // })
        .catch((e: any) => console.log(e));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext]);

  useEffect(() => {
    const handleLocalization = async (lang: string) => {
      await loadTranslations(lang);

      SET_MODAL_STEPS_TITLE(getModalStepsTitle(selectedGender));
    };

    const lang = initializeLanguage();
    handleLocalization(lang);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGender]);

  useEffect(() => {
    // first screen for mobile
    if (isMobile && step?.number === MODAL_STEPS.INTRO.number) {
      setStep(MODAL_STEPS.GENDER);
    }
    if (!isMobile && step?.number === MODAL_STEPS.GENDER.number) {
      setStep(MODAL_STEPS.INTRO);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, isMobile]);

  useEffect(() => {
    if (recommendedSize) {
      setStep(MODAL_STEPS.RESULT);
    }
  }, [recommendedSize]);

  useEffect(() => {
    if (userContext && step?.number === MODAL_STEPS.QR_CODE.number)
      userContext.newMeasure("scan", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, userContext]);

  const handleFieldChange = (e: any, type: string, blurSignal: string) => {
    const isCustomField =
      type === "age" || type === "feet" || type === "inches";
    const value = isCustomField ? e : e.target.value;

    switch (type) {
      case "height":
        setHeight(value);
        if (unit === "feet") {
          convertHeightToFeetAndInches(value);
        }
        break;
      case "feet":
        setFeet(value);
        convertFeetAndInchesToCm(value, inches);
        break;
      case "inches":
        setInches(value);
        convertFeetAndInchesToCm(feet, value);
        break;
      case "weight":
        setWeight(value);
        break;
      case "age":
        setAge(value);
        break;
      default:
        break;
    }
  };

  const handleUnitChange = (newUnit: string) => {
    if (newUnit !== null) {
      setUnit(newUnit);
      if (newUnit === "feet" && height) {
        const { feetValue, inchesValue } = convertHeightToFeetAndInches(height);
        setFeet(feetValue);
        setInches(inchesValue);
      } else if (newUnit === "cm" && (feet || inches)) {
        const heightStr = convertFeetAndInchesToCm(feet, inches);
        setHeight(heightStr);
      }
    }
  };

  const handleUnitWeightChange = (newUnit: string) => {
    if (newUnit !== null) {
      setUnitWeight(newUnit);
    }
  };

  useEffect(() => {
    if (localGender) {
      setSelectedGender(localGender);
    }
  }, [localGender]);

  const handleGenderSelect = (gender: string) => {
    setSelectedGender(gender);
    localStorage.setItem("gender", gender.replace(`"`, ""));
  };

  useEffect(() => {
    if (recommendedSize) {
      let adjustedSize = Object.values(recommendedSize) as SizeDataType[];

      const emptyElement = {
        label: "",
        variant_id: "",
        possible: 0,
        label_rank: 0,
        fit_indicators: [],
      };

      adjustedSize = adjustedSize.sort((a, b) => a.label_rank - b.label_rank);

      if (adjustedSize.length < 3) {
        if (
          adjustedSize[0]?.label_rank === 1 &&
          adjustedSize[1]?.label_rank === 2
        ) {
          adjustedSize = [emptyElement, ...adjustedSize];
        } else if (
          adjustedSize[0]?.label_rank === 0 &&
          adjustedSize[1]?.label_rank === 1
        ) {
          adjustedSize.push(emptyElement);
        }
      }

      const reducedResultRes: any = {};
      if (adjustedSize[0]) reducedResultRes[0] = adjustedSize[0];
      if (adjustedSize[1]) reducedResultRes[1] = adjustedSize[1];
      if (adjustedSize[2]) reducedResultRes[2] = adjustedSize[2];

      setReducedResult(reducedResultRes);
    }
  }, [recommendedSize]);

  const fieldsValidation = () => {
    const newError = {
      height: { error: false, message: "" },
      weight: { error: false, message: "" },
      age: { error: false, message: "" },
    };

    const heightValue = parseInt(height, 10);
    const localFeet = feet ? Number(feet.split(" ")[0]) : null;
    const localInches = inches ? Number(inches.split(" ")[0]) : null;
    const localWeight = weight ? Number(weight.split(" ")[0]) : null;
    const localAge = age ? Number(age.split(" ")[0]) : null;

    const rule = VALIDATION_RULES;

    if (
      unit === "cm" &&
      (heightValue < rule.height.min || heightValue > rule.height.max)
    ) {
      newError.height = { error: true, message: t("height.errorcm") };
    }

    if (unit === "feet" && localFeet && localInches) {
      const totalInches = localFeet * 12 + localInches; // convert feet and inches to total inches
      const minHeightInches = rule.height.feet.min * 12 + rule.height.feet.max; // 4 feet 5 inches = 53 inches
      const maxHeightInches =
        rule.height.inches.min * 12 + rule.height.inches.max; // 6 feet 9 inches = 81 inches

      if (totalInches < minHeightInches || totalInches > maxHeightInches) {
        newError.height = { error: true, message: t("height.errorfeet") };
      }
    }

    if (
      (unitWeight === "kg" &&
        localWeight &&
        (localWeight < rule.weight.kg.min ||
          localWeight > rule.weight.kg.max)) ||
      localWeight === 0
    ) {
      newError.weight = { error: true, message: t("weight.errorkg") };
    } else if (
      unitWeight === "lbs" &&
      localWeight &&
      (localWeight < rule.weight.lbs.min || localWeight > rule.weight.lbs.max)
    ) {
      newError.weight = { error: true, message: t("weight.errorlbs") };
    }

    if (
      (localAge && (localAge < rule.age.min || localAge > rule.age.max)) ||
      localAge === 0
    ) {
      newError.age = { error: true, message: t("age.error") };
    }

    setError(newError);
  };

  // all fields validation
  useEffect(() => {
    if (needValidate) fieldsValidation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needValidate, height, unit, weight, unitWeight, age, feet, inches]);

  const handleGoBack = () => {
    if (isMobile && step?.number === MODAL_STEPS.DEVICE_SELECT.number) {
      setStep(MODAL_STEPS.INTRO_MOBILE);
    } else if (!isMobile && step?.number === MODAL_STEPS.DEVICE_SELECT.number) {
      setStep(MODAL_STEPS.INTRO);
    } else if (step?.number === MODAL_STEPS.QR_CODE.number) {
      if (
        !previousStep &&
        (uxRoute === "question_pushScan" || uxRoute === "question_only")
      ) {
        setStep(isMobile ? MODAL_STEPS.INTRO_MOBILE : MODAL_STEPS.INTRO);
      } else if (previousStep !== null) {
        setStep(previousStep);
        setPreviousStep(null);
      } else {
        setStep(MODAL_STEPS.DEVICE_SELECT);
      }
    } else if (step?.number === MODAL_STEPS.BELLY.number) {
      if (uxRoute === "question_only") {
        setStep(isMobile ? MODAL_STEPS.INTRO_MOBILE : MODAL_STEPS.INTRO);
      } else {
        setStep(MODAL_STEPS.DEVICE_SELECT);
      }
    } else if (!uxGender && step?.number === MODAL_STEPS.INTRO_MOBILE.number) {
      setStep(MODAL_STEPS.GENDER);
    } else if (step?.number === MODAL_STEPS.TORSO.number) {
      setStep(MODAL_STEPS.BELLY);
    } else if (step?.number === MODAL_STEPS.CUISSES.number) {
      setStep(MODAL_STEPS.TORSO);
    } else if (step?.number === MODAL_STEPS.BREASTS.number) {
      setStep(
        uxRoute === "none" && selectedGender === GENDERS.F
          ? defaultStep
          : MODAL_STEPS.TORSO
      );
    } else if (step?.number === MODAL_STEPS.RESULT.number) {
      restart();
    } else if (step?.number === MODAL_STEPS.ERROR.number) {
      restart();
    }

    setPreviousStep(null);
  };

  const computeDisableContinue = (): boolean => {
    const hasValidationErrors = Object.values(error).some(
      (field) => field.error
    );
    const stableFieldsNotFound = !weight || !age || !selectedGender;

    if (isMobile) {
      if (step === MODAL_STEPS.GENDER) {
        return !selectedGender;
      }
      if (step === MODAL_STEPS.INTRO_MOBILE) {
        return unit === "feet"
          ? !feet || !inches || hasValidationErrors || stableFieldsNotFound
          : !height || stableFieldsNotFound || hasValidationErrors;
      }
    } else {
      return unit === "feet"
        ? !feet || !inches || hasValidationErrors || stableFieldsNotFound
        : !height || stableFieldsNotFound || hasValidationErrors;
    }

    // Default return value
    return false;
  };

  // check disable continue status
  useEffect(() => {
    const disabledContinue = computeDisableContinue();

    setDisableContinue(disabledContinue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    unit,
    selectedGender,
    feet,
    inches,
    height,
    weight,
    age,
    error,
    step,
    isMobile,
  ]);

  useEffect(() => {
    const handleMessage = async (event: any) => {
      const eventData = event?.data;
      if (eventData?.sizes?.length) {
        const sizesData = eventData?.sizes?.map((item: any) => ({
          variant_reference: item?.variantId,
          quantity: item?.quantity,
        }));
        setProductStockData(sizesData);
      }

      // if (
      //   !resultScreenUI.generateResult &&
      //   !productStockData?.length &&
      //   !stocksChecked
      // ) {
      //   console.log("TCL: DrawerModal -> stocksChecked", stocksChecked);

      //   const urlParameters = new URLSearchParams(window.location.search);
      //   const product_id = urlParameters.get("product_id");
      //   const domain = urlParameters.get("domain");

      //   if (domain && product_id) {
      //     const stocksData = await checkStocks(domain, product_id);
      //     console.log("TCL: handleMessage -> stocksData", stocksData);
      //     setProductStockData(stocksData);
      //   }
      // }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    window.parent.postMessage({ action: "getSizes" }, "*");
  }, []);

  const goToResultScreen = async (isResultGenerated?: boolean) => {
    const urlParams = new URLSearchParams(window.location.search);
    const pid = urlParams.get("product_id");

    try {
      const result = await recommend(pid);

      if (result) {
        setRecommendedSize(result);

        if (!selectedGender && localGender) {
          setSelectedGender(localGender);
        } else if (
          !selectedGender &&
          !localGender &&
          !localGender.includes(GENDERS.F)
        ) {
          setSelectedGender(GENDERS.M);
          localStorage.setItem("gender", GENDERS.M);
        }

        if (!resultScreenUI.generateResult && !productStockData?.length) {
          if (domain && pid) {
            const stocksData = await checkStocks(domain, pid);
            setProductStockData(stocksData);
          }
        } else {
          setStep(MODAL_STEPS.RESULT);
        }
      } else if (!recommendedSize?.length && !result && selectedRoute) {
        setStep(isResultGenerated ? MODAL_STEPS.ERROR : defaultStep);
        removeLocalStore("gender");
        if (!uxGender) setSelectedGender("");
      } else {
        setStep(defaultStep);
        removeLocalStore("gender");
        if (!uxGender) setSelectedGender("");
      }
    } catch (error) {
      console.error("error: ", error);
      setStep(MODAL_STEPS.ERROR);
    }
  };

  useEffect(() => {
    const findVariant = productStockData?.find(
      (item: any) => item.variant_reference === selectedSize?.variant_id
    );

    const isAvailableSize =
      typeof findVariant?.quantity === "boolean"
        ? findVariant?.quantity
        : findVariant?.quantity >= 1;

    if (findVariant) {
      setIsSizeUnavailable(!isAvailableSize); // size unavailable

      setStep(
        !isAvailableSize ? MODAL_STEPS.UNAVAILABLE_RESULT : MODAL_STEPS.RESULT
      );
    }
  }, [productStockData, selectedSize]);

  useEffect(() => {
    const handleMessage = (event: any) => {
      const eventData = event?.data;

      if (eventData?.sizes?.length) {
        const sizesData = eventData?.sizes?.map((item: any) => ({
          variant_reference: item?.variantId,
          quantity: item?.quantity,
        }));

        setProductStockData(sizesData);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    window.parent.postMessage({ action: "getSizes" }, "*");
  }, []);

  // send data after result
  const sendQuestionAnswers = async () => {
    const isMale = selectedGender === GENDERS.M;
    const heightLocal =
      unit === "feet" ? INCHtoCM(`${feet} ${inches}`) : height;
    const weightLocal =
      unitWeight === "lbs" && weight ? LBStoKG(parseInt(weight)) : weight;
    const commonData = {
      gender: isMale ? "male" : "female",
      age: age ? parseInt(age, 10) : 0,
      height: heightLocal ? parseInt(heightLocal, 10) : 0,
      weight: weightLocal ? parseInt(weightLocal, 10) : 0,
    };

    const defaultQuestions = isMale
      ? { male_belly: 2, male_hip: 2, male_chest: 2 }
      : {
          female_belly: 2,
          female_hip: 2,
          female_bra_size: sizeValue.current || "90",
          female_bra_cup: cupValue.current || "B",
        };

    const dynamicQuestions = isMale
      ? {
          male_belly: bellyValue.current || 2,
          male_hip: torsoValue.current || 2,
          male_chest: cuissesValue.current || 2,
        }
      : {
          female_belly: bellyValue.current || 2,
          female_hip: torsoValue.current || 2,
          female_bra_size: sizeValue.current || "90",
          female_bra_cup: cupValue.current || "B",
        };

    const data = {
      ...commonData,
      questions: uxRoute === "none" ? defaultQuestions : dynamicQuestions,
    };

    const result = await questionPushAnswers(data);

    if (result) await goToResultScreen(true);

    return result;
  };

  // restart and refresh all data
  const restart = () => {
    setStep(uxConsentScreen ? MODAL_STEPS.CONSENT : defaultStep);
    setHeight("");
    setUnit("cm");
    setFeet("");
    setInches("");
    setWeight("");
    setUnitWeight("kg");
    setAge("");
    // !uxGender && setSelectedGender("");
    setSelectedGender("");
    removeLocalStore("gender");
    setDisableContinue(true);
    bellyValue.current = 0;
    torsoValue.current = 0;
    cuissesValue.current = 0;
    sizeValue.current = "";
    cupValue.current = "";
    setRecommendedSize(null);
    setReducedResult(null);
  };

  const closeIframe = () =>
    window.parent.postMessage({ action: "closeIframe" }, "*");

  useEffect(() => {
    return () => restart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const titleTextTransform =
    titleStyles.textTransform as React.CSSProperties["textTransform"];

  return (
    <Box
      className="drawer-overlay"
      style={{ fontFamily: `${font}, sans-serif` }}
      onClick={() => closeIframe()}
    >
      <Box
        className="drawer-popup"
        onClick={(e) => e.stopPropagation()}
        style={{
          fontFamily: `${font}, sans-serif`,
          backgroundColor: brandDefined?.name === "SRP" ? "#F7F7F7" : "#FFFFFF",
          paddingTop: isMobile && brandDefined?.name === "SRP" ? 0 : "20px",
        }}
      >
        <Box
          className="drawer-popup-header"
          style={{
            marginTop:
              isMobile && brandDefined?.name === "SRP"
                ? 0
                : isMobile && brandDefined?.name !== "SRP"
                ? "10px"
                : 0,
            marginBottom:
              step?.number === MODAL_STEPS.RESULT.number ||
              step?.number === MODAL_STEPS.UNAVAILABLE_RESULT.number ||
              step?.number === MODAL_STEPS.ERROR.number
                ? 0
                : "10px",
            width: brandDefined?.name === "SRP" ? "calc(100% + 50px)" : "100%",
            position: brandDefined?.name === "SRP" ? "relative" : "initial",
            right: brandDefined?.name === "SRP" ? "25px" : "0",
            bottom: brandDefined?.name === "SRP" ? "20px" : "0",
            backgroundColor:
              brandDefined?.name === "SRP" ? "#FFFFFF" : "inherit",
            minHeight: brandDefined?.name === "SRP" ? "45px" : "auto",
          }}
        >
          <div style={{ position: "absolute", left: "15px" }}>
            {step?.number >= 0 &&
              step?.number !== MODAL_STEPS.CONSENT.number &&
              step?.number !== MODAL_STEPS.GENDER.number &&
              step?.number !== MODAL_STEPS.INTRO.number &&
              step?.number !== MODAL_STEPS.ERROR.number &&
              step?.number !== MODAL_STEPS.RESULT.number &&
              step?.number !== MODAL_STEPS.UNAVAILABLE_RESULT.number &&
              step?.number !== MODAL_STEPS.ANTI_BRACKETING.number && (
                <IconButton
                  sx={{
                    zIndex: 1,
                    width: isSmallMobile ? "28px" : isMobile ? "31px" : "34px",
                    height: isSmallMobile ? "28px" : isMobile ? "31px" : "34px",
                    padding: "3px",
                    borderRadius: "5px",
                  }}
                  onClick={handleGoBack}
                >
                  <ArrowBackIcon
                    style={{
                      color: "black",
                      width: isMobile ? "16.5px" : "13.5px",
                      height: isMobile ? "14px" : "11px",
                    }}
                  />
                </IconButton>
              )}
          </div>
          {brandDefined?.name === "SRP" ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: 400,
                  color: "#272626",
                  textAlign: "center",
                }}
              >
                Guide des tailles
              </span>
            </div>
          ) : null}
          {!isMobile && (
            <h2
              style={{
                textAlign:
                  titleStyles.textAlign as React.CSSProperties["textAlign"],
                fontWeight:
                  titleStyles.fontWeight as React.CSSProperties["fontWeight"],
                textTransform:
                  titleTextTransform === "capitalize"
                    ? "none"
                    : titleTextTransform,
                color: titleStyles.color as React.CSSProperties["color"],
                fontSize:
                  titleStyles.fontSize as React.CSSProperties["fontSize"],
                minHeight: "25px",
                position:
                  brandDefined?.name === "SRP" ? "absolute" : "relative",
                left: brandDefined?.name === "SRP" ? "25px" : "",
                top: brandDefined?.name === "SRP" ? "50px" : "",
              }}
            >
              {titleTextTransform === "capitalize"
                ? capitalizeFirstLetter(MODAL_STEPS_TITLE[step?.number])
                : MODAL_STEPS_TITLE[step?.number]}
            </h2>
          )}
          {step?.number >= 0 ? (
            <IconButton
              onClick={() => closeIframe()}
              className="close-icon"
              sx={{
                width: isSmallMobile ? "28px" : isMobile ? "45px" : "34px",
                height: isSmallMobile ? "28px" : isMobile ? "45px" : "34px",
              }}
            >
              <CloseIcon
                className="icon"
                style={{
                  color: "black",
                  width: isMobile ? "16px" : "11px",
                  height: isMobile ? "16px" : "11px",
                }}
              />
            </IconButton>
          ) : null}
        </Box>
        <Box
          className="drawer-popup-content"
          style={{
            backgroundColor:
              brandDefined?.name === "SRP" ? "#F7F7F7" : "#FFFFFF",
          }}
        >
          {isMobile && step?.number !== MODAL_STEPS.QR_CODE.number && (
            <h2
              style={{
                textAlign:
                  titleStyles.textAlign as React.CSSProperties["textAlign"],
                fontWeight:
                  titleStyles.fontWeight as React.CSSProperties["fontWeight"],
                textTransform:
                  titleTextTransform === "capitalize"
                    ? "none"
                    : titleTextTransform,
                color: titleStyles.color as React.CSSProperties["color"],
                fontSize:
                  titleStyles.fontSize as React.CSSProperties["fontSize"],
                minHeight: "25px",
              }}
            >
              {titleTextTransform === "capitalize"
                ? capitalizeFirstLetter(MODAL_STEPS_TITLE[step?.number])
                : MODAL_STEPS_TITLE[step?.number]}
            </h2>
          )}
          <DrawerSteps
            step={step}
            error={error}
            height={height}
            unit={unit}
            feet={feet}
            inches={inches}
            unitWeight={unitWeight}
            weight={weight}
            age={age}
            selectedGender={selectedGender}
            disableContinue={disableContinue}
            bellyValue={bellyValue}
            torsoValue={torsoValue}
            cuissesValue={cuissesValue}
            sizeValue={sizeValue}
            cupValue={cupValue}
            sendQuestionAnswers={sendQuestionAnswers}
            handleFieldChange={handleFieldChange}
            handleUnitChange={handleUnitChange}
            handleUnitWeightChange={handleUnitWeightChange}
            handleGenderSelect={handleGenderSelect}
            setNeedValidate={setNeedValidate}
            setRecommendedSize={setRecommendedSize}
            goToResultScreen={goToResultScreen}
            reducedResult={reducedResult}
            selectedRoute={selectedRoute}
            setSelectedRoute={setSelectedRoute}
            sizeCountry={sizeCountry}
            setSizeCountry={setSizeCountry}
            productStockData={productStockData}
            similarProducts={similarProducts}
            isSizeUnavailable={isSizeUnavailable}
            selectedSize={selectedSize}
            setSelectedSize={setSelectedSize}
            setStep={setStep}
            setPreviousStep={setPreviousStep}
            restart={restart}
            setSimilarProducts={setSimilarProducts}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DrawerModal;
