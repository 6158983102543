import { SvgIcon, SvgIconProps } from "@mui/material";

const ArrowIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.05279 12.5641C9.04036 12.7027 9.07224 12.8227 9.15397 12.9045C9.18463 12.9351 9.22069 12.9588 9.2611 12.9757C9.31925 13.0565 9.38735 13.1369 9.46447 13.214L12.2929 16.0424C12.6834 16.4329 13.1572 16.5923 13.3511 16.3984C13.545 16.2045 13.3856 15.7308 12.995 15.3403L10.2532 12.5984C10.2547 12.5969 10.2562 12.5954 10.2577 12.594L13.0861 9.76553C13.4766 9.375 13.6156 8.88085 13.3966 8.66181C13.1776 8.44277 12.6834 8.58179 12.2929 8.97231L9.46447 11.8007C9.37345 11.8918 9.2961 11.9884 9.23376 12.0859C9.18473 12.0988 9.14231 12.1219 9.10843 12.1558C9.01952 12.2447 9.0049 12.3925 9.05279 12.5641Z"
      fill={props?.fill || "#2E2E2E"}
    />
  </SvgIcon>
);

export default ArrowIcon;
