import { getConfig } from "./configLoader";

// Get the configuration
const config = getConfig();

export const uxGender = config.ux.gender;
export const uxRoute = config.ux.route;
export const uxConsentScreen = config.ux.consentScreen;
export const uxFitFeedbacks = config.ux.fitFeedbacks;

export const font = config.ui.all.font;
export const titleStyles = config.ui.all.titles;
export const subtitlesStyles = config.ui.all.subtitles;
export const ctaStyles = config.ui.all.cta;
export const loaderStyles = config.ui.all.loader;
export const measuredByStyles = config.ui.all.measured_by;

export const scanCTAStyle = config.ui.routeParameters.scan.CTA;
export const stepperStyle = config.ui.routeParameters.questions.stepper;

export const welcomeScreenUI = config.ui[1];
export const choiceScreenUI = config.ui[2];
export const bodyScanScreenUI = config.ui[3];
export const questionOneScreenUI = config.ui[4];
export const questionTwoScreenUI = config.ui[5];
export const questionThreeScreenUI = config.ui[6];
export const resultScreenUI = config.ui[7];

// Custom styles for buttons
export const activeStylesContinue = {
  backgroundColor: ctaStyles.active
    .backgroundColor as React.CSSProperties["backgroundColor"],
  fontWeight: ctaStyles.active.fontWeight as React.CSSProperties["fontWeight"],
  fontSize: ctaStyles.active.fontSize as React.CSSProperties["fontSize"],
  color: ctaStyles.active.fontColor as React.CSSProperties["color"],
  textTransform: ctaStyles.active
    .textTransform as React.CSSProperties["textTransform"],
  borderRadius: ctaStyles.active
    .borderRadius as React.CSSProperties["borderRadius"],
  borderWidth: ctaStyles.active
    .borderWidth as React.CSSProperties["borderWidth"],
  borderColor: ctaStyles.active
    .borderColor as React.CSSProperties["borderColor"],
  borderStyle: "solid",
  fontFamily: `${font}, sans-serif`,
};

const convertPercentageToOpacity = (percentage: string): number => {
  return parseInt(percentage) / 100;
};

const backgroundColorStyles = (ctaStyles.disabled as any)?.backgroundOpacity
  ? `rgba(
  ${parseInt(ctaStyles.disabled.backgroundColor.slice(1, 3), 16)},
  ${parseInt(ctaStyles.disabled.backgroundColor.slice(3, 5), 16)},
  ${parseInt(ctaStyles.disabled.backgroundColor.slice(5, 7), 16)},
  ${convertPercentageToOpacity((ctaStyles.disabled as any)?.backgroundOpacity)}
  )`
  : (ctaStyles.disabled
      .backgroundColor as React.CSSProperties["backgroundColor"]);

const fontColorStyles = (ctaStyles.disabled as any)?.fontOpacity
  ? `rgba(
  ${parseInt(ctaStyles.disabled.fontColor.slice(1, 3), 16)},
  ${parseInt(ctaStyles.disabled.fontColor.slice(3, 5), 16)},
  ${parseInt(ctaStyles.disabled.fontColor.slice(5, 7), 16)},
  ${convertPercentageToOpacity((ctaStyles.disabled as any)?.fontOpacity)}
  )`
  : (ctaStyles.disabled.fontColor as React.CSSProperties["color"]);

export const disabledStylesContinue = {
  backgroundColor: backgroundColorStyles,
  fontWeight: ctaStyles.disabled
    .fontWeight as React.CSSProperties["fontWeight"],
  fontSize: ctaStyles.disabled.fontSize as React.CSSProperties["fontSize"],
  color: fontColorStyles,
  textTransform: ctaStyles.disabled
    .textTransform as React.CSSProperties["textTransform"],
  borderRadius: ctaStyles.disabled
    .borderRadius as React.CSSProperties["borderRadius"],
  borderTopWidth: ctaStyles.disabled
    .borderWidth as React.CSSProperties["borderTopWidth"],
  borderBottomWidth: ctaStyles.disabled
    .borderWidth as React.CSSProperties["borderBottomWidth"],
  borderLeftWidth: ctaStyles.disabled
    .borderWidth as React.CSSProperties["borderLeftWidth"],
  borderRightWidth: ctaStyles.disabled
    .borderWidth as React.CSSProperties["borderRightWidth"],
  borderTopColor: ctaStyles.disabled
    .borderColor as React.CSSProperties["borderTopColor"],
  borderBottomColor: ctaStyles.disabled
    .borderColor as React.CSSProperties["borderBottomColor"],
  borderLeftColor: ctaStyles.disabled
    .borderColor as React.CSSProperties["borderLeftColor"],
  borderRightColor: ctaStyles.disabled
    .borderColor as React.CSSProperties["borderRightColor"],
  borderStyle: "solid",
  cursor: "default",
  fontFamily: `${font}, sans-serif`,
};
