import { brandsArray } from "../configs/configLoader";
import { API_ENDPOINT, GENDERS } from "../constants/modal";
import {
  questionMalePushAnswersSchema,
  questionFemalePushAnswersSchema,
} from "../validation/pushAnswers";

type MeasureType = "question" | "scan";

const uuidRegex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const newUser = async (domain: string) => {
  if (domain) {
    localStorage.setItem("domain", domain);

    const uid = await fetch(`${API_ENDPOINT}new-user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        retailer: domain,
        device_id: "abcdefghiju",
      }),
    })
      .then((res) => res.json())
      .then((res) => res["user_id"])
      .catch((e) => {
        console.log("Error: newUser - ", e);
        return false;
      });

    if (uid) {
      localStorage.setItem("uid", uid);
      return uid;
    } else {
      return false;
    }
  } else {
    localStorage.setItem("domain", brandsArray?.[0]?.domains?.[0] || "");
  }
};

export const recommend = async (pid: string | null) => {
  const mid = localStorage.getItem("mid") || null;
  if (mid && uuidRegex.test(mid) && pid) {
    const success = await fetch(`${API_ENDPOINT}recommend?version=1.0.4`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        measure_public_id: mid,
        product_reference: pid,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res?.recommendation) {
          return res?.recommendation;
        } else {
          return null;
        }
      })
      .catch((e) => {
        console.log("Error: recommend - ", e);
        return false;
      });
    return success;
  } else {
    return false;
  }
};

export const newMeasure = async (
  measure_type: MeasureType,
  user_id?: string,
  retailer_customer_tracking_id?: string | null
) => {
  const uid = localStorage.getItem("uid") || user_id || null;

  if (uid && uuidRegex.test(uid)) {
    const mid = await fetch(`${API_ENDPOINT}new-measure`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: uid,
        measure_type: measure_type,
        retailer_customer_tracking_id,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        const resMid = res["measure_id"];
        window.parent.postMessage({ data: "mid", mid: resMid }, "*");
        return resMid;
      })
      .catch((e) => {
        console.log("Error: newMeasure to see - ", e);
        return false;
      });

    if (mid) {
      localStorage.setItem("mid", mid);
      return mid;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const scanCheckMeasurements = async () => {
  const mid = localStorage.getItem("mid") || null;
  if (mid) {
    const success = await fetch(`${API_ENDPOINT}scan-check-measurements`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        measure_id: mid,
      }),
    })
      .then((res) => res.json())
      .then((res) => res["completed"])
      .catch((e) => {
        console.log("Error: scanCheckMeasurements - ", e);
        return false;
      });
    return success;
  } else {
    return false;
  }
};

export const questionPushAnswers = async (answers: object) => {
  const mid = localStorage.getItem("mid") || null;
  if (mid && uuidRegex.test(mid)) {
    const body = {
      measure_id: mid,
      answers: answers,
    };

    try {
      const gender = (answers as any).gender;
      if (gender === GENDERS.M) {
        questionMalePushAnswersSchema.parse(body);
      } else if (gender === GENDERS.F) {
        questionFemalePushAnswersSchema.parse(body);
      } else {
        throw new Error("Invalid gender value");
      }

      const success = await fetch(`${API_ENDPOINT}question-push-trigger`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((res) => res)
        .catch((e) => {
          console.log("Error: questionPushAnswersAndTrigger - ", e);
          return false;
        });

      return !!success;
    } catch (error) {
      console.error("Validation Error:", error);
      return false;
    }
  } else {
    return false;
  }
};

export const checkStocks = async (domain: string, pid: string | null) => {
  if (pid) {
    const success = await fetch(`${API_ENDPOINT}stocks`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        retailer_domain: domain,
        product_reference: pid,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res?.data) {
          return res?.data;
        } else {
          return null;
        }
      })
      .catch((e) => {
        console.log("Error: recommend - ", e);
        return null;
      });
    return success;
  } else {
    return false;
  }
};

export const findSimilarProducts = async (
  domain: string,
  pid: string,
  variant_id: string | null
) => {
  const success = await fetch(`${API_ENDPOINT}similar-products`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      retailer: domain,
      product_reference: pid,
      variant_reference: variant_id,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res) {
        return res;
      } else {
        return null;
      }
    })
    .catch((e) => {
      console.log("Error: recommend - ", e);
      return null;
    });
  return success;
};
