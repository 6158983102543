import { useEffect } from "react";
import DrawerModal from "./components/DrawerModal";

import { applyFontFromConfig, font } from "./configs";

import "./App.css";

function App() {
  useEffect(() => {
    applyFontFromConfig(font);
  }, []);

  return <DrawerModal />;
}

export default App;
