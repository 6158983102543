import { FC } from "react";
import { useMediaQuery } from "react-responsive";

import StepIntroDesktop from "../../../StepIntroDesktop";
import StepDeviceSelect from "../../../StepDeviceSelect";
import StepQRCode from "../../../StepQRCode";
import StepBelly from "../../../StepBelly";
import StepTorse from "../../../StepTorse";
import StepCuisses from "../../../StepCuisses";
import StepBreasts from "../../../StepBreasts";
import ResultError from "../../../ResultError";
import Result from "../../../Result";
import ConsentScreen from "../../../ConsentScreen";

import { GENDERS, MODAL_STEPS } from "../../../../constants/modal";
import { newMeasure } from "../../../../api/endpoints";
import { SizeDataType } from "../../../../types/result";
import {
  removeLocalStore,
  setLocalStore,
} from "../../../../store/localStoreUtils";
import {
  subtitlesStyles,
  titleStyles,
  uxGender,
  uxRoute,
} from "../../../../configs";
import { useUserContext } from "../../../../store/userContext";

import "./index.css";

interface IPropsDrawerSteps {
  step: any;
  error: any;
  height: string;
  unit: string;
  feet: string;
  inches: string;
  unitWeight: string;
  weight?: string | undefined;
  age: string;
  selectedGender: string;
  disableContinue: boolean;
  bellyValue: any;
  torsoValue: any;
  cuissesValue: any;
  sizeValue: any;
  cupValue: any;
  sendQuestionAnswers: () => void;
  handleFieldChange: (e: any, type: string, blurSignal: string) => void;
  handleUnitChange: (newUnit: string) => void;
  handleUnitWeightChange: (newUnit: string) => void;
  handleGenderSelect: (newUnit: string) => void;
  setNeedValidate: React.Dispatch<React.SetStateAction<boolean>>;
  setRecommendedSize: React.Dispatch<any>;
  goToResultScreen: () => void;
  reducedResult: any;
  selectedRoute: string;
  setSelectedRoute: React.Dispatch<React.SetStateAction<string>>;
  sizeCountry: string;
  setSizeCountry: React.Dispatch<React.SetStateAction<string>>;
  productStockData: any;
  similarProducts: any;
  isSizeUnavailable: boolean;
  selectedSize: SizeDataType | null;
  setSelectedSize: React.Dispatch<React.SetStateAction<SizeDataType | null>>;
  setSimilarProducts: React.Dispatch<any>;
  setStep: (
    value: React.SetStateAction<{
      number: number;
    }>
  ) => void;
  setPreviousStep: React.Dispatch<
    React.SetStateAction<{
      number: number;
    } | null>
  >;
  restart: () => void;
}

const DrawerSteps: FC<IPropsDrawerSteps> = ({
  step,
  error,
  height,
  unit,
  feet,
  inches,
  unitWeight,
  weight,
  age,
  selectedGender,
  disableContinue,
  bellyValue,
  torsoValue,
  cuissesValue,
  sizeValue,
  cupValue,
  sendQuestionAnswers,
  handleFieldChange,
  handleUnitChange,
  handleUnitWeightChange,
  handleGenderSelect,
  setNeedValidate,
  setRecommendedSize,
  goToResultScreen,
  reducedResult,
  selectedRoute,
  setSelectedRoute,
  sizeCountry,
  setSizeCountry,
  productStockData,
  similarProducts,
  isSizeUnavailable,
  selectedSize,
  setSelectedSize,
  setStep,
  setPreviousStep,
  restart,
  setSimilarProducts,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const userContext = useUserContext() || undefined;

  const urlParameters = new URLSearchParams(window.location.search);
  const product_id = urlParameters.get("product_id");
  const domain = urlParameters.get("domain");

  const titlesStyles = {
    color: titleStyles.color as React.CSSProperties["color"],
    fontSize: titleStyles.fontSize as React.CSSProperties["fontSize"],
    fontWeight: titleStyles.fontWeight,
    textAlign: titleStyles.textAlign as React.CSSProperties["textAlign"],
    textTransform:
      titleStyles.textTransform as React.CSSProperties["textTransform"],
    justifyContent:
      titleStyles.textAlign as React.CSSProperties["justifyContent"],
  };

  const subtitleStyles = {
    color: subtitlesStyles.color as React.CSSProperties["color"],
    fontSize: subtitlesStyles.fontSize as React.CSSProperties["fontSize"],
    fontWeight: subtitlesStyles.fontWeight as React.CSSProperties["fontWeight"],
    textAlign: subtitlesStyles.textAlign as React.CSSProperties["textAlign"],
    textTransform:
      subtitlesStyles.textTransform as React.CSSProperties["textTransform"],
    justifyContent:
      subtitlesStyles.textAlign as React.CSSProperties["justifyContent"],
  };

  const handleRouteEvent = async (type: string) => {
    if (type === "question_pushScan") {
      await newMeasure("scan");
      setStep(MODAL_STEPS.QR_CODE);
    }

    if (type === "question_only") {
      await newMeasure("question");
      setStep(MODAL_STEPS.BELLY);
    }

    setSelectedRoute(type);
  };

  const defaultStep =
    isMobile && uxGender
      ? MODAL_STEPS.INTRO_MOBILE
      : isMobile
      ? MODAL_STEPS.GENDER
      : MODAL_STEPS.INTRO;

  const handleNextStep = async () => {
    removeLocalStore("mid");
    removeLocalStore("uid");
    removeLocalStore("gender");

    if (userContext) {
      userContext
        .newUser(domain)
        .then(async (v: any) => {
          if (uxRoute === "none") {
            restart();
          } else {
            if (selectedRoute === "question_pushScan") {
              await newMeasure("scan");
              setStep(MODAL_STEPS.QR_CODE);
            }

            if (selectedRoute === "question_only") {
              await newMeasure("question");
              setStep(defaultStep);
            }
          }
        })
        .catch((e: any) => console.log(e));
    }
  };

  const handleSaveUserInfo = async () => {
    setLocalStore(
      "user",
      JSON.stringify({
        gender: selectedGender,
        age,
        height,
        weight,
      })
    );
  };

  return (
    <>
      {step?.number === MODAL_STEPS.CONSENT.number && (
        <ConsentScreen
          nextStep={() => {
            setStep(isMobile ? MODAL_STEPS.GENDER : MODAL_STEPS.INTRO);
          }}
        />
      )}
      {(step?.number === MODAL_STEPS.INTRO.number ||
        step?.number === MODAL_STEPS.GENDER.number ||
        step?.number === MODAL_STEPS.INTRO_MOBILE.number) && (
        <StepIntroDesktop
          step={step}
          error={error}
          height={height}
          unit={unit}
          feet={feet}
          inches={inches}
          unitWeight={unitWeight}
          weight={weight}
          age={age}
          selectedGender={selectedGender}
          disableContinue={disableContinue}
          handleFieldChange={handleFieldChange}
          handleUnitChange={handleUnitChange}
          handleUnitWeightChange={handleUnitWeightChange}
          handleGenderSelect={handleGenderSelect}
          setNeedValidate={setNeedValidate}
          subtitleStyles={subtitleStyles}
          nextStep={async () => {
            if (uxRoute === "none" && selectedGender === GENDERS.M) {
              await newMeasure("question");
              sendQuestionAnswers();
            }

            if (uxRoute === "none" && selectedGender === GENDERS.F) {
              await newMeasure("question");
              setStep(MODAL_STEPS.BREASTS);
            }

            if (uxRoute === "question_pushScan") {
              await handleRouteEvent(uxRoute);
              setSelectedRoute(uxRoute);
            }
            if (uxRoute === "question_only") {
              await handleRouteEvent(uxRoute);
              setSelectedRoute(uxRoute);
            }

            // desktop first screen
            if (!uxRoute && step?.number === MODAL_STEPS.INTRO.number) {
              setStep(MODAL_STEPS.DEVICE_SELECT);
            }

            // mobile first screens
            if (!uxGender && step?.number === MODAL_STEPS.GENDER.number) {
              setStep(MODAL_STEPS.INTRO_MOBILE);
            }

            if (!uxRoute && step?.number === MODAL_STEPS.INTRO_MOBILE.number) {
              setStep(MODAL_STEPS.DEVICE_SELECT);
            }

            handleSaveUserInfo();
          }}
        />
      )}
      {step?.number === MODAL_STEPS.DEVICE_SELECT.number && (
        <StepDeviceSelect
          nextPhotoStep={async () => {
            await handleRouteEvent("question_pushScan");
            setSelectedRoute("question_pushScan");
          }}
          nextQuestionsStep={async () => {
            await handleRouteEvent("question_only");
            setSelectedRoute("question_only");
          }}
        />
      )}
      {step?.number === MODAL_STEPS.QR_CODE.number && (
        <StepQRCode
          pid={product_id}
          gender={selectedGender}
          skipToResult={() => setStep(MODAL_STEPS.RESULT)}
          setRecommendedSize={setRecommendedSize}
          titleStyles={titlesStyles}
          subtitleStyles={subtitleStyles}
        />
      )}
      {step?.number === MODAL_STEPS.BELLY.number && (
        <StepBelly
          gender={selectedGender}
          nextStep={() => {
            setStep(MODAL_STEPS.TORSO);
          }}
          setStep={setStep}
          setPreviousStep={setPreviousStep}
          value={bellyValue}
          subtitleStyles={subtitleStyles}
        />
      )}
      {step?.number === MODAL_STEPS.TORSO.number && (
        <StepTorse
          gender={selectedGender}
          nextStep={() => {
            if (selectedGender === GENDERS.M) {
              setStep(MODAL_STEPS.CUISSES);
            } else {
              setStep(MODAL_STEPS.BREASTS);
            }
          }}
          setStep={setStep}
          setPreviousStep={setPreviousStep}
          value={torsoValue}
          subtitleStyles={subtitleStyles}
        />
      )}
      {step?.number === MODAL_STEPS.CUISSES.number && (
        <StepCuisses
          gender={selectedGender}
          value={cuissesValue}
          nextStep={async () => sendQuestionAnswers()}
          setStep={setStep}
          setPreviousStep={setPreviousStep}
          subtitleStyles={subtitleStyles}
        />
      )}
      {step?.number === MODAL_STEPS.BREASTS.number && (
        <StepBreasts
          value_1={sizeValue}
          value_2={cupValue}
          nextStep={async () => sendQuestionAnswers()}
          sizeCountry={sizeCountry}
          setSizeCountry={setSizeCountry}
          subtitleStyles={subtitleStyles}
        />
      )}
      {step?.number === MODAL_STEPS.ERROR.number && (
        <ResultError
          restart={restart}
          nextStep={async () => await handleNextStep()}
          skip={async () => {
            if (selectedRoute === "question_pushScan") {
              await newMeasure("question");
              setStep(defaultStep);
            }

            if (selectedRoute === "question_only") {
              await newMeasure("question");
              setStep(MODAL_STEPS.QR_CODE);
            }
          }}
        />
      )}
      {(step?.number === MODAL_STEPS.RESULT.number ||
        step?.number === MODAL_STEPS.UNAVAILABLE_RESULT.number) && (
        <Result
          reducedResult={reducedResult}
          selectedGender={selectedGender}
          productStockData={productStockData}
          similarProducts={similarProducts}
          isSizeUnavailable={isSizeUnavailable}
          selectedSize={selectedSize}
          setSelectedSize={setSelectedSize}
          restart={restart}
          setSimilarProducts={setSimilarProducts}
        />
      )}
    </>
  );
};

export default DrawerSteps;
