import { z } from "zod";

// `male` push answers validation
export const questionMalePushAnswersSchema = z.object({
  measure_id: z.string().uuid(),
  answers: z.object({
    gender: z.literal("male"),
    age: z.number(),
    height: z.number(),
    weight: z.number(),
    questions: z.object({
      male_belly: z.number().int().min(1).max(3),
      male_hip: z.number().int().min(1).max(3),
      male_chest: z.number().int().min(1).max(3),
    }),
  }),
});

// `female` push answers validation
export const questionFemalePushAnswersSchema = z.object({
  measure_id: z.string().uuid(),
  answers: z.object({
    gender: z.literal("female"),
    age: z.number(),
    height: z.number(),
    weight: z.number(),
    questions: z.object({
      female_belly: z.number().int().min(1).max(3),
      female_hip: z.number().int().min(1).max(3),
      female_bra_size: z.string().nullable(),
      female_bra_cup: z.string().nullable(),
    }),
  }),
});
