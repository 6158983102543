import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Checkbox } from "@mui/material";
import { styled } from "@mui/system";

import { activeStylesContinue, titleStyles } from "../../configs";
import { capitalizeFirstLetter } from "../../utils";

import "./index.css";

interface IPropsConsentScreen {
  nextStep: () => void;
}

const ConsentScreen: FC<IPropsConsentScreen> = ({ nextStep }) => {
  const { t } = useTranslation("components/consentement");

  const [checked, setChecked] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const description = t("description");
  const paragraphs = description.split("\n\n");

  const makeLinksClickable = (text: string) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    return text.split(urlPattern).map((part: string, index: number) => {
      if (part.match(urlPattern)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      return part;
    });
  };

  return (
    <div className="consent">
      <div className="consent-description">
        {paragraphs.map((paragraph, index) => (
          <p key={index} style={{ marginBottom: "15px" }}>
            {/* {paragraph} */}
            {makeLinksClickable(paragraph)}
          </p>
        ))}
      </div>
      <div className="check-container">
        <CustomCheckbox
          checked={checked}
          onChange={handleChange}
          style={{
            color: "#000",
            margin: 0,
            padding: 0,
          }}
        />
        <p
          className="breasts-description"
          style={{
            textAlign:
              titleStyles.textAlign as React.CSSProperties["textAlign"],
            justifyContent:
              titleStyles.textAlign as React.CSSProperties["textAlign"],
          }}
        >
          {t("access")}
        </p>
      </div>
      <button
        type="button"
        className="continue-button breasts-continue"
        onClick={() => nextStep()}
        style={{
          ...activeStylesContinue,
          textTransform:
            activeStylesContinue.textTransform === "capitalize"
              ? "none"
              : activeStylesContinue.textTransform,
        }}
      >
        {activeStylesContinue.textTransform === "capitalize"
          ? capitalizeFirstLetter(t("submit"))
          : t("submit")}
      </button>
    </div>
  );
};

const CustomCheckbox = styled(Checkbox)({
  "&:before": {
    content: '""',
    display: "block",
    width: "14px",
    height: "14px",
    border: "1px solid #000",
    backgroundColor: "#fff",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  "&.Mui-checked": {
    "&:before": {
      backgroundColor: "#fff",
    },
    "&:after": {
      content: '""',
      display: "block",
      width: "4px",
      height: "4px",
      backgroundColor: "#000",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
});

export default ConsentScreen;
