import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import MeasuredBy from "../molecules/MeasuredBy";

import {
  activeStylesContinue,
  welcomeScreenUI,
  titleStyles as titleStylesBase,
  subtitlesStyles,
} from "../../configs";
import { capitalizeFirstLetter } from "../../utils";

import "./index.css";

interface IPropsResultError {
  nextStep: () => void;
  restart: () => void;
  skip: () => void;
}

const ResultError: FC<IPropsResultError> = ({ nextStep, restart, skip }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { t } = useTranslation("components/results/error");

  const titleTextTransform = welcomeScreenUI.titles
    .textTransform as React.CSSProperties["textTransform"];

  const titlesStyles = {
    fontWeight: "400",
    textAlign: welcomeScreenUI.titles
      .textAlign as React.CSSProperties["textAlign"],
    textTransform:
      titleTextTransform === "capitalize" ? "none" : titleTextTransform,
    color: titleStylesBase.color as React.CSSProperties["color"],
    fontSize: "14px",
    justifyContent: welcomeScreenUI.titles
      .textAlign as React.CSSProperties["justifyContent"],
  };

  // const skipTextTransform = resultScreenUI.restartCTA
  //   .textTransform as React.CSSProperties["textTransform"];

  // const skipStyles = {
  //   backgroundColor: resultScreenUI.restartCTA
  //     .backgroundColor as React.CSSProperties["backgroundColor"],
  //   fontWeight: resultScreenUI.restartCTA
  //     .fontWeight as React.CSSProperties["fontWeight"],
  //   fontSize: resultScreenUI.restartCTA
  //     .fontSize as React.CSSProperties["fontSize"],
  //   color: resultScreenUI.restartCTA.fontColor as React.CSSProperties["color"],
  //   textTransform:
  //     skipTextTransform === "capitalize" ? "none" : skipTextTransform,
  //   borderRadius: resultScreenUI.restartCTA
  //     .borderRadius as React.CSSProperties["borderRadius"],
  //   borderColor: resultScreenUI.restartCTA
  //     .borderColor as React.CSSProperties["borderColor"],
  //   borderWidth: resultScreenUI.restartCTA
  //     .borderWidth as React.CSSProperties["borderWidth"],
  // };

  return (
    <div className="result-error">
      <span
        className="description"
        style={{
          ...titlesStyles,
          marginTop: isMobile ? 0 : "25px",
          textTransform: (subtitlesStyles.textTransform === "capitalize"
            ? "none"
            : subtitlesStyles.textTransform) as React.CSSProperties["textTransform"],
        }}
      >
        {subtitlesStyles.textTransform === "capitalize"
          ? capitalizeFirstLetter(t("error.description"))
          : t("error.description")}
      </span>

      <button
        type="button"
        className="continue-button"
        onClick={() => nextStep()}
        style={{
          ...activeStylesContinue,
          textTransform:
            activeStylesContinue.textTransform === "capitalize"
              ? "none"
              : activeStylesContinue.textTransform,
        }}
      >
        {activeStylesContinue.textTransform === "capitalize"
          ? capitalizeFirstLetter(t("skip"))
          : t("skip")}
      </button>

      {/* {uxRoute !== "none" && (
        <span className="skip-error" onClick={() => skip()} style={skipStyles}>
          {skipTextTransform === "capitalize"
            ? capitalizeFirstLetter(t("error.skip"))
            : t("error.skip")}
        </span>
      )} */}
      <MeasuredBy />
    </div>
  );
};

export default ResultError;
