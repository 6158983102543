import { FC } from "react";
import { useTranslation } from "react-i18next";

import { activeStylesContinue } from "../../configs";
import { GENDERS } from "../../constants/modal";

import { Help as HelpIcon } from "@mui/icons-material";
import { capitalizeFirstLetter } from "../../utils";

import "./index.css";

interface IPropsQrcodeMobile {
  loading?: boolean;
  url?: string;
  gender: string | undefined;
}

const QrcodeMobile: FC<IPropsQrcodeMobile> = ({ loading, url, gender }) => {
  const { t } = useTranslation("components/qrcode/mobile");

  const openScan = () => {
    if (!loading) {
      window.open(url, "_blank");
    }
  };

  return (
    <div className="mobile-qr-container">
      <video muted className="qrcode-video" playsInline autoPlay loop>
        <source
          src={
            gender === GENDERS.M
              ? require("../../assets/qrcode/demo-male.mp4")
              : require("../../assets/qrcode/demo-female.mp4")
          }
          type="video/mp4"
        />
      </video>
      <div className="help-info">
        <HelpIcon />
        <span className="link">{t("info")}</span>
      </div>
      <button
        type="button"
        className="continue-button"
        onClick={openScan}
        style={{
          ...activeStylesContinue,
          textTransform:
            activeStylesContinue.textTransform === "capitalize"
              ? "none"
              : activeStylesContinue.textTransform,
        }}
      >
        {activeStylesContinue.textTransform === "capitalize"
          ? capitalizeFirstLetter(t("scan"))
          : t("scan")}
      </button>
    </div>
  );
};

export default QrcodeMobile;
