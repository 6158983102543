import { welcomeScreenUI } from "../../configs";

import "./index.css";

interface TextFieldComponentProps {
  option1: string;
  option2: string;
  unit: string;
  handleUnitChange: (newUnit: string) => void;
}

const UnitOptions: React.FC<TextFieldComponentProps> = ({
  option1,
  option2,
  unit,
  handleUnitChange,
}) => {
  const unitsStyles = {
    fontWeight: welcomeScreenUI.units.fontWeight,
    fontSize: welcomeScreenUI.units.fontSize,
    top: welcomeScreenUI.units.top,
    right: welcomeScreenUI.units.right,
  };

  return (
    <div className="unit-options">
      <span
        style={{
          ...unitsStyles,
          color:
            unit === option1
              ? welcomeScreenUI.units.activeColor
              : welcomeScreenUI.units.inactiveColor,
          position: "relative",
          cursor: "pointer",
          textDecoration:
            unit === option1
              ? (welcomeScreenUI?.units as { activeFontStyle?: string })
                  ?.activeFontStyle || ""
              : "",
        }}
        onClick={() => handleUnitChange(option1)}
      >
        {option1}
      </span>
      <span
        style={{
          ...unitsStyles,
          color:
            unit === option2
              ? welcomeScreenUI.units.activeColor
              : welcomeScreenUI.units.inactiveColor,
          position: "relative",
          cursor: "pointer",
          textDecoration:
            unit === option2
              ? (welcomeScreenUI?.units as { activeFontStyle?: string })
                  ?.activeFontStyle || ""
              : "",
        }}
        onClick={() => handleUnitChange(option2)}
      >
        {option2}
      </span>
    </div>
  );
};

export default UnitOptions;
