import { FC, useEffect, useState } from "react";
import { MenuItem, Select } from "@mui/material";

import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

import MeasuredBy from "../molecules/MeasuredBy";

import { BASE_SIZES } from "../../constants/modal";

import { ArrowBackIos as ArrowLowerIcon } from "@mui/icons-material";
import {
  disabledStylesContinue,
  activeStylesContinue,
  questionThreeScreenUI,
  welcomeScreenUI,
  font,
  titleStyles,
} from "../../configs";
import { capitalizeFirstLetter } from "../../utils";

import "./index.css";

interface IPropsStepBreasts {
  value_1: {
    current: string;
  };
  value_2: {
    current: string;
  };
  nextStep: () => void;
  sizeCountry: string;
  setSizeCountry: React.Dispatch<React.SetStateAction<string>>;
  subtitleStyles: {
    color: React.CSSProperties["color"];
    fontSize: React.CSSProperties["fontSize"];
    fontWeight: React.CSSProperties["fontWeight"];
    textAlign: React.CSSProperties["textAlign"];
    textTransform: React.CSSProperties["textTransform"];
    justifyContent: React.CSSProperties["justifyContent"];
  };
}

type BodyItemsType = "empty" | "filled";

const StepBreasts: FC<IPropsStepBreasts> = ({
  value_1,
  value_2,
  nextStep,
  sizeCountry,
  setSizeCountry,
  subtitleStyles,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [open, setOpen] = useState(false);

  const { t } = useTranslation("components/breasts");

  const [selectedSize, setSelectedSize] = useState<any | undefined>();
  const [selectedCup, setSelectedCup] = useState<any | undefined>();

  useEffect(() => {
    if (selectedSize !== undefined && selectedCup !== undefined) {
      value_1.current =
        sizeCountry === "FR"
          ? selectedSize?.size?.toString()
          : selectedSize?.fr?.toString();
      value_2.current =
        sizeCountry === "FR"
          ? selectedCup?.size?.toString()
          : selectedCup?.fr?.toString();
    }
  }, [sizeCountry, selectedSize, selectedCup, value_1, value_2]);

  const fontFamily = `${font}, sans-serif`;

  const getBodyItemsStyles = (type: BodyItemsType) => {
    const bodyItemsStyles = {
      backgroundColor: questionThreeScreenUI.female.boxes[type].backgroundColor,
      // borderColor: questionThreeScreenUI.female.boxes[type].borderColor,
      borderRadius: questionThreeScreenUI.female.boxes[type].borderRadius,
      borderWidth: questionThreeScreenUI.female.boxes[type].borderWidth,
      color: questionThreeScreenUI.female.boxes[type].fontColor,
      fontSize: questionThreeScreenUI.female.boxes[type].fontSize,
      fontWeight: questionThreeScreenUI.female.boxes[type]
        .fontWeight as React.CSSProperties["fontWeight"],
      padding: questionThreeScreenUI.female.boxes[type].padding,
      textTransform: questionThreeScreenUI.female.boxes[type]
        .cap as React.CSSProperties["textTransform"],
      border: `${questionThreeScreenUI.female.boxes[type].borderWidth} solid ${questionThreeScreenUI.female.boxes[type].borderColor}`,
      height: isMobile
        ? "24px"
        : `calc(${questionThreeScreenUI.female.boxes[type].padding} * 3)`,
      fontFamily,
    };

    return bodyItemsStyles;
  };

  const langDropdownStyles = {
    color: questionThreeScreenUI.female.country.dropdown.color,
    borderColor: questionThreeScreenUI.female.country.dropdown.borderColor,
    borderWidth: questionThreeScreenUI.female.country.dropdown.borderWidth,
    borderRadius: questionThreeScreenUI.female.country.dropdown.borderRadius,
  };

  const titleTextTransform =
    titleStyles.textTransform as React.CSSProperties["textTransform"];

  const titlesStyles = {
    fontWeight: welcomeScreenUI.titles.fontWeight,
    textAlign: welcomeScreenUI.titles
      .textAlign as React.CSSProperties["textAlign"],
    textTransform:
      titleTextTransform === "capitalize" ? "none" : titleTextTransform,
    color: welcomeScreenUI.titles.color as React.CSSProperties["color"],
    fontSize: isMobile
      ? "14px"
      : (welcomeScreenUI.titles.fontSize as React.CSSProperties["fontSize"]),
    justifyContent: welcomeScreenUI.titles
      .textAlign as React.CSSProperties["justifyContent"],
  };

  return (
    <div className="breasts">
      <p
        className="breasts-description"
        style={{
          ...subtitleStyles,
          textTransform:
            subtitleStyles.textTransform === "capitalize"
              ? "none"
              : (subtitleStyles.textTransform as React.CSSProperties["textTransform"]),
          marginBottom: isMobile ? "25px" : "15px",
        }}
      >
        {subtitleStyles.textTransform === "capitalize"
          ? capitalizeFirstLetter(t("description"))
          : t("description")}
      </p>
      <div
        className="breasts__titles"
        style={{
          display:
            questionThreeScreenUI.female.country.align === "same"
              ? "flex"
              : "block",
        }}
      >
        <p className="breasts__titles__title" style={titlesStyles}>
          {titleTextTransform === "capitalize"
            ? capitalizeFirstLetter(t("pays"))
            : t("pays")}
        </p>
        <div onClick={() => setOpen(!open)}>
          <Select
            value={sizeCountry}
            onChange={(event) => {
              setSizeCountry(event.target.value);
            }}
            open={open}
            onClose={() => setOpen(false)}
            className="breasts__select"
            IconComponent={(props) => (
              <RotatedArrowIcon open={open} {...props} />
            )}
            MenuProps={{
              MenuListProps: {
                sx: {
                  padding: 0,
                  fontFamily,
                  textAlign: "center",
                  "& .Mui-selected": {
                    display: "none",
                  },
                },
              },
              PaperProps: {
                sx: {
                  fontFamily,
                  padding: 0,
                  textAlign: "center",
                  border: `${langDropdownStyles.borderWidth} solid ${langDropdownStyles.borderColor}`,
                  borderTopColor: "transparent",
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  boxShadow: "none !important",
                  transform: "translateY(-5px) !important",
                },
              },
            }}
            sx={{
              fontFamily,
              border: `${langDropdownStyles.borderWidth} solid ${langDropdownStyles.borderColor}`,
              borderBottomColor: open
                ? "transparent"
                : langDropdownStyles.borderColor,
              borderBottomLeftRadius: open
                ? 0
                : langDropdownStyles.borderRadius,
              borderBottomRightRadius: open
                ? 0
                : langDropdownStyles.borderRadius,
            }}
          >
            <MenuItem
              className="breasts__select__item"
              value="FR"
              style={{
                fontFamily,
              }}
            >
              <span
                className="breasts__select__item__flag-icons"
                style={{
                  color: questionThreeScreenUI.female.country.dropdown.color,
                }}
              >
                FR
              </span>
            </MenuItem>
            <MenuItem
              className="breasts__select__item"
              value="UK"
              style={{ fontFamily }}
            >
              <span
                className="breasts__select__item__flag-icons"
                style={{
                  color: questionThreeScreenUI.female.country.dropdown.color,
                }}
              >
                UK
              </span>
            </MenuItem>
            <MenuItem
              className="breasts__select__item"
              value="IT"
              style={{ fontFamily }}
            >
              <span
                className="breasts__select__item__flag-icons"
                style={{
                  color: questionThreeScreenUI.female.country.dropdown.color,
                }}
              >
                IT
              </span>
            </MenuItem>
            <MenuItem
              className="breasts__select__item"
              value="US"
              style={{ fontFamily }}
            >
              <span
                className="breasts__select__item__flag-icons"
                style={{
                  color: questionThreeScreenUI.female.country.dropdown.color,
                }}
              >
                US
              </span>
            </MenuItem>
            <MenuItem
              className="breasts__select__item"
              value="JP"
              style={{ fontFamily }}
            >
              <span
                className="breasts__select__item__flag-icons"
                style={{
                  color: questionThreeScreenUI.female.country.dropdown.color,
                }}
              >
                JP
              </span>
            </MenuItem>
            <MenuItem
              className="breasts__select__item"
              value="KO"
              style={{ fontFamily }}
            >
              <span
                className="breasts__select__item__flag-icons"
                style={{
                  color: questionThreeScreenUI.female.country.dropdown.color,
                }}
              >
                KO
              </span>
            </MenuItem>
            <MenuItem
              className="breasts__select__item"
              value="AUS"
              style={{ fontFamily }}
            >
              <span
                className="breasts__select__item__flag-icons"
                style={{
                  color: questionThreeScreenUI.female.country.dropdown.color,
                }}
              >
                AUS
              </span>
            </MenuItem>
            <MenuItem
              className="breasts__select__item"
              value="EU"
              style={{ fontFamily }}
            >
              <span
                className="breasts__select__item__flag-icons"
                style={{
                  color: questionThreeScreenUI.female.country.dropdown.color,
                }}
              >
                EU
              </span>
            </MenuItem>
          </Select>
        </div>
      </div>
      <div className="breasts__titles">
        <p className="breasts__titles__title" style={titlesStyles}>
          {titleTextTransform === "capitalize"
            ? capitalizeFirstLetter(t("size"))
            : t("size")}
        </p>
      </div>
      <div className="breasts__body">
        <table className="breasts__body__table">
          <tbody>
            <tr>
              {BASE_SIZES.find(
                (value: any) => value.name === sizeCountry
              )?.band_sizes.map((row: any) => (
                <td key={row?.size}>
                  <button
                    className={`breasts__body__table__cell ${
                      selectedSize && selectedSize?.size === row?.size
                        ? "breasts__body__table__cell--selected"
                        : undefined
                    }`}
                    onClick={() => setSelectedSize(row)}
                    style={Object.assign({
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width:
                        row?.size?.toString()?.length > 4
                          ? "auto"
                          : isMobile
                          ? "24px"
                          : `calc(${
                              questionThreeScreenUI.female.boxes[
                                selectedSize?.size === row?.size
                                  ? "filled"
                                  : "empty"
                              ].padding
                            } * 3)`,
                      ...(selectedSize?.size === row?.size
                        ? getBodyItemsStyles("filled")
                        : getBodyItemsStyles("empty")),
                    })}
                  >
                    {row?.size}
                  </button>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        <div className="breasts__titles">
          <p
            className="breasts__titles__title"
            style={{
              ...titlesStyles,
              position: "relative",
              top: isMobile ? 0 : "10px",
            }}
          >
            {titleTextTransform === "capitalize"
              ? capitalizeFirstLetter(t("cup"))
              : t("cup")}
          </p>
        </div>
        <table className="breasts__body__table">
          <tbody>
            <tr>
              {BASE_SIZES.find(
                (value: any) => value.name === sizeCountry
              )?.cup_sizes.map((row) => (
                <td key={row?.size} className="breasts__body__table__test">
                  <button
                    className={`breasts__body__table__cell ${
                      selectedCup && selectedCup?.size === row?.size
                        ? "breasts__body__table__cell--selected"
                        : undefined
                    }`}
                    onClick={() => setSelectedCup(row)}
                    style={Object.assign({
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width:
                        row?.size?.toString()?.length > 4
                          ? "auto"
                          : isMobile
                          ? "24px"
                          : `calc(${
                              questionThreeScreenUI.female.boxes[
                                selectedCup?.size === row?.size
                                  ? "filled"
                                  : "empty"
                              ].padding
                            } * 3)`,
                      ...(selectedCup?.size === row?.size
                        ? getBodyItemsStyles("filled")
                        : getBodyItemsStyles("empty")),
                    })}
                  >
                    {row?.size}
                  </button>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
      <button
        type="button"
        disabled={selectedSize === undefined || selectedCup === undefined}
        className="continue-button breasts-continue"
        onClick={() => nextStep()}
        style={{
          marginTop: isMobile ? "60px" : "30px",
          position: "relative",
          marginBottom: isMobile ? "20px" : "0",
          ...(selectedSize === undefined || selectedCup === undefined
            ? disabledStylesContinue
            : activeStylesContinue),
        }}
      >
        {t("continue")}
      </button>
      <span
        className="breasts-skip"
        onClick={() => nextStep()}
        style={{
          color: questionThreeScreenUI.female.skip.fontColor,
          fontWeight: questionThreeScreenUI.female.skip.fontWeight,
          fontSize: questionThreeScreenUI.female.skip.fontSize,
          textDecoration: questionThreeScreenUI.female.skip.fontStyle,
        }}
      >
        {t("skip")}
      </span>
      <MeasuredBy />
    </div>
  );
};

const RotatedArrowIcon = ({ open }: { open: boolean }) => {
  return (
    <ArrowLowerIcon
      sx={{
        transform: open ? "rotate(90deg)" : "rotate(-90deg)",
        transition: "transform 0.3s ease",
        fontSize: "14px",
        position: "relative",
        top: open ? "4px" : "-4px",
        right: "7px",
      }}
    />
  );
};

export default StepBreasts;
