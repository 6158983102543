// import { measuredByStyles } from "../../../configs";

import "./index.css";

const MeasuredBy = () => {
  const stylesTextStyles = {
    // color: measuredByStyles.text.fontColor as React.CSSProperties["color"],
    // fontSize: measuredByStyles.text.fontSize as React.CSSProperties["fontSize"],
    // fontWeight: measuredByStyles.text
    //   .fontWeight as React.CSSProperties["fontWeight"],
  };

  const stylesLogoStyles = {
    // color: measuredByStyles.logo.fontColor as React.CSSProperties["color"],
    // fontSize: measuredByStyles.logo.fontSize as React.CSSProperties["fontSize"],
    // fontWeight: measuredByStyles.logo
    //   .fontWeight as React.CSSProperties["fontWeight"],
  };

  return (
    <div className="measured-by" style={stylesTextStyles}>
      Measured by <span style={stylesLogoStyles}>kleep</span>{" "}
    </div>
  );
};

export default MeasuredBy;
