import { SvgIconProps } from "@mui/material";

const ArrowSelectIcon = (props: SvgIconProps) => (
  <svg
    width="9"
    height="16"
    viewBox="0 0 9 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 1L8 8L1 15" stroke="#002D18" />
  </svg>
);

export default ArrowSelectIcon;
