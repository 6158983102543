import lacosteConfig from "./configFiles/lacoste.json";
import kenzoConfig from "./configFiles/kenzo.json";
import pldtConfig from "./configFiles/pldt.json";
import sportyandrichConfig from "./configFiles/sportyandrich.json";
import LSFConfig from "./configFiles/LSF.json";
import IZACConfig from "./configFiles/IZAC.json";
import JOTTConfig from "./configFiles/JOTT.json";
import FFConfig from "./configFiles/FF.json";
import CircleSportsWearConfig from "./configFiles/CircleSportsWear.json";
import RodierConfig from "./configFiles/Rodier.json";
import LaCanadienneConfig from "./configFiles/LaCanadienne.json";
import GerardDarelConfig from "./configFiles/GerardDarel.json";
import RonDorffConfig from "./configFiles/RonDorff.json";
import KooplesConfig from "./configFiles/Kooples.json";
import ChloreConfig from "./configFiles/Chlore.json";
import MolliConfig from "./configFiles/Molli.json";
import PlaceDuJourConfig from "./configFiles/PlaceDuJour.json";
import GualapConfig from "./configFiles/Gualap.json";
import VogStoreConfig from "./configFiles/Vogstore.json";
import SRPConfig from "./configFiles/SRP.json";
import HartfordConfig from "./configFiles/Hartford.json";
import LaPetiteEtoileConfig from "./configFiles/LaPetiteEtoile.json";
import MisterKConfig from "./configFiles/MisterK.json";

export const brandsArray = [
  {
    name: "Lacoste",
    domains: ["lacoste.com"],
    config: lacosteConfig,
  },
  {
    name: "KENZO",
    domains: ["kenzo.com", "stg.kenzo.com", "sb03.kenzo.com"],
    config: kenzoConfig,
  },
  {
    name: "Sporty & Rich",
    domains: ["sportyandrich.com"],
    config: sportyandrichConfig,
  },
  {
    name: "Place des Tendances",
    domains: ["placedestendances.com"],
    config: pldtConfig,
  },
  {
    name: "Le Slip Français",
    domains: ["www.leslipfrancais.fr"],
    config: LSFConfig,
  },
  {
    name: "IZAC",
    domains: ["izac.fr"],
    config: IZACConfig,
  },
  {
    name: "JOTT",
    domains: ["jott.com", "justoverthetop-testing.myshopify.com"],
    config: JOTTConfig,
  },
  {
    name: "From Future",
    domains: ["ff.com"],
    config: FFConfig,
  },
  {
    name: "Circle Sportwear",
    domains: ["circlesportswear.com"],
    config: CircleSportsWearConfig,
  },
  {
    name: "Rodier",
    domains: ["rodier.fr", "preprod02-fo-rodier.wshop.cloud"],
    config: RodierConfig,
  },
  {
    name: "La Canadienne",
    domains: ["la-canadienne.com"],
    config: LaCanadienneConfig,
  },
  {
    name: "Gerard Darel",
    domains: ["gerarddarel.com"],
    config: GerardDarelConfig,
  },
  {
    name: "Ron Dorff",
    domains: ["rondorff.com", "uk.rondorff.com", "us.rondorff.com"],
    config: RonDorffConfig,
  },
  {
    name: "The Kooples",
    domains: ["thekooples.com"],
    config: KooplesConfig,
  },
  {
    name: "Chlore",
    domains: ["www.chlore-swimwear.fr"],
    config: ChloreConfig,
  },
  {
    name: "Molli",
    domains: ["molli.com"],
    config: MolliConfig,
  },
  {
    name: "Place du Jour",
    domains: ["placedujour.fr"],
    config: PlaceDuJourConfig,
  },
  {
    name: "Gualap",
    domains: ["gualap.com"],
    config: GualapConfig,
  },
  {
    name: "Vogstore",
    domains: ["vogstoree.com"],
    config: VogStoreConfig,
  },
  {
    name: "SRP",
    domains: ["showroomprive.com"],
    config: SRPConfig,
  },
  {
    name: "Hartford",
    domains: ["hartford.fr"],
    config: HartfordConfig,
  },
  {
    name: "La Petite Etoile",
    domains: ["lapetiteetoile.com"],
    config: LaPetiteEtoileConfig,
  },
  {
    name: "Mister K",
    domains: ["misterk.fr"],
    config: MisterKConfig,
  },
];

// Function to extract query parameter from the URL
export const getQueryParam = (param: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

// Function to get the config based on domain
export const getConfig = () => {
  const domain = getQueryParam("domain") || brandsArray?.[0]?.domains?.[0];
  const brand = brandsArray.find((brand) => brand.domains.includes(domain));

  if (brand) {
    return brand.config;
  } else {
    return brandsArray?.[0]?.config;
  }
};
