import { useMediaQuery } from "react-responsive";

import { TextField, InputAdornment } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import { brandsArray } from "../../configs/configLoader";
import { welcomeScreenUI } from "../../configs";

import "./index.css";

interface TextFieldComponentProps {
  id: string;
  value?: string;
  unit: string;
  placeholder: string;
  handleChange: (e: any) => void;
  setBlurSignal: React.Dispatch<React.SetStateAction<string>>;
  setNeedValidate: React.Dispatch<React.SetStateAction<boolean>>;
  error: boolean;
}

const TextFieldComponent: React.FC<TextFieldComponentProps> = ({
  id,
  value,
  unit,
  placeholder,
  handleChange,
  setBlurSignal,
  setNeedValidate,
  error,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const urlParameters = new URLSearchParams(window.location.search);
  const domain = urlParameters.get("domain");

  const brandDefined = brandsArray.find((brand) =>
    domain ? brand.domains.includes(domain) : null
  );

  const borderColor = error ? "#DA3939" : welcomeScreenUI.inputFields.fontColor;
  const borderColorValue =
    !welcomeScreenUI.inputFields.borderBottom && isMobile
      ? "transparent"
      : isMobile && error
      ? "#DA3939"
      : welcomeScreenUI.inputFields.borderColor;

  const inputStyles = {
    backgroundColor: welcomeScreenUI.inputFields.backgroundColor,
    borderColor: borderColorValue,
    borderRadius: isMobile
      ? welcomeScreenUI.inputFields?.mobileBorderRadius
      : welcomeScreenUI.inputFields.borderRadius,
    borderWidth: welcomeScreenUI.inputFields.borderWidth,
    color:
      (!value
        ? welcomeScreenUI.inputFields.fontColor
        : error
        ? "#DA3939"
        : welcomeScreenUI.inputFields.hoverColor) + " !important",
    borderBottomColor: error
      ? "#DA3939"
      : welcomeScreenUI.inputFields.borderColor,
    fontSize: welcomeScreenUI.inputFields.fontSize + " !important",
    marginBottom: welcomeScreenUI.inputFields
      .marginBottom as React.CSSProperties["marginBottom"],
    padding: isMobile
      ? "12px 20px"
      : (welcomeScreenUI.inputFields.padding as React.CSSProperties["padding"]),
    borderBottom:
      brandDefined?.name !== "SRP" && welcomeScreenUI.inputFields.borderBottom
        ? `1px solid ${
            error ? "#DA3939" : welcomeScreenUI.inputFields.borderColor
          }`
        : "none",
    fontWeight:
      ((!value
        ? welcomeScreenUI.inputFields.fontWeight
        : welcomeScreenUI.inputFields
            .fontWeightFilled) as React.CSSProperties["fontWeight"]) +
      " !important",
    border:
      brandDefined?.name === "SRP"
        ? `${welcomeScreenUI.inputFields.borderWidth} solid ${borderColorValue}`
        : "",
  };

  const handleFocus = (event: any) => {
    if (isMobile) {
      event.target.scrollIntoView({ behavior: "smooth", block: "center" });
      const popup = document.querySelector(".drawer-popup") as HTMLElement;
      if (popup) {
        popup.style.position = "fixed";
        popup.style.zIndex = "999";
        popup.style.bottom = "0";
      }
    }
  };

  const handleBlur = (e: any) => {
    setBlurSignal(error ? id : "");
    setNeedValidate(true);

    if (isMobile) {
      const popup = document.querySelector(".drawer-popup") as HTMLElement;
      if (popup) {
        popup.style.bottom = "0";
      }
    }
  };

  const inputs = document.querySelectorAll("input");

  inputs.forEach((input) => {
    input.addEventListener("touchstart", (event: any) => {
      event.preventDefault();
      input.focus({ preventScroll: true });
    });
  });

  document.addEventListener(
    "touchstart",
    function (event: any) {
      if (event.target.tagName === "INPUT") {
        event.preventDefault();
        event.target.focus({ preventScroll: true });
      }
    },
    { passive: false }
  );

  return (
    <TextField
      id={id}
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        const inputValue = e.target.value;

        const validateNumeric = /^\d*$/.test(inputValue);
        const validateFeet = /^[0-9'"]*$/.test(inputValue);

        if (unit === "feet" ? validateFeet : validateNumeric) {
          setNeedValidate(false);
          handleChange(e);
        }
      }}
      onFocus={handleFocus}
      onBlur={handleBlur}
      inputProps={{
        pattern: "[0-9]*",
      }}
      sx={{
        width: "100%",
        "& .MuiInput-input": {
          ...inputStyles,
          marginBottom: !isMobile ? inputStyles.marginBottom : 0,
        },
        "& .MuiInput-underline:after, & .MuiInput-underline:before, &:hover .MuiInput-underline:before, & .MuiInput-underline:focus":
          {
            borderColor: borderColor,
            borderBottom:
              inputStyles.borderBottom === "none"
                ? "none"
                : isMobile
                ? "none"
                : `1px solid ${borderColor}`,
            borderBottomWidth: isMobile ? "0" : "1px",
            borderBottomLeftRadius: isMobile ? "4px" : "0",
            borderBottomRightRadius: isMobile ? "4px" : "0",
          },
        "& .MuiInput-underline:before": {
          borderBottom: "none !important",
        },
        "& .MuiInput-underline:after": {
          borderBottom: "none !important",
        },
        "&:hover .MuiInput-underline:before": {
          borderBottom: "none !important",
        },
        "& .MuiInput-underline:hover:before": {
          borderBottom: "none !important",
        },
        "& .MuiInput-underline:focus": {
          borderBottom: "none !important",
        },
        "& .MuiInputAdornment-root": {
          position: "absolute",
          right: "10px",
          top: "12px",
        },
        "& input:-webkit-autofill": {
          ...inputStyles,
          backgroundColor: "#FFF !important",
          boxShadow: "0 0 0px 1000px #FFF inset !important",
        },
      }}
      variant="standard"
      slotProps={{
        input: {
          endAdornment:
            isMobile && error ? (
              <InputAdornment position="end">
                <ErrorIcon sx={{ color: "#DA3939" }} />
              </InputAdornment>
            ) : null,
        },
      }}
    />
  );
};

export default TextFieldComponent;
