import ReactDOM from "react-dom/client";
import App from "./App";
import { UserContextProvider } from "./store/userContext";

import "./fonts/index.css";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <UserContextProvider>
    <App />
  </UserContextProvider>
);
