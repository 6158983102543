import {
  uxGender,
  uxRoute,
  uxConsentScreen,
  uxFitFeedbacks,
  font,
  titleStyles,
  subtitlesStyles,
  ctaStyles,
  loaderStyles,
  measuredByStyles,
  scanCTAStyle,
  stepperStyle,
  welcomeScreenUI,
  choiceScreenUI,
  bodyScanScreenUI,
  questionOneScreenUI,
  questionTwoScreenUI,
  questionThreeScreenUI,
  resultScreenUI,
  activeStylesContinue,
  disabledStylesContinue,
} from "./stylesLoader";

import { getConfig, getQueryParam } from "./configLoader";
import { applyFontFromConfig } from "./fontLoader";

// Load the configuration and apply the font
const config = getConfig();

// Export everything for use in other parts of the application
export {
  uxGender,
  uxRoute,
  uxConsentScreen,
  uxFitFeedbacks,
  font,
  titleStyles,
  subtitlesStyles,
  ctaStyles,
  loaderStyles,
  measuredByStyles,
  scanCTAStyle,
  stepperStyle,
  welcomeScreenUI,
  choiceScreenUI,
  bodyScanScreenUI,
  questionOneScreenUI,
  questionTwoScreenUI,
  questionThreeScreenUI,
  resultScreenUI,
  activeStylesContinue,
  disabledStylesContinue,
  config,
  getConfig,
  getQueryParam,
  applyFontFromConfig,
};
