import React, { FC, useState, useRef } from "react";
import { useMediaQuery } from "react-responsive";

import { useTranslation } from "react-i18next";

import ArrowIcon from "../../../../assets/icons/ArrowIcon";

import "./index.css";

interface IPropsUnavailableGallery {
  similarProducts: Array<{ image_url: string; title: string }>;
}

const UnavailableGallery: FC<IPropsUnavailableGallery> = ({
  similarProducts,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { t } = useTranslation("components/results/result");

  const scrollRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeftX, setScrollLeftX] = useState(0);
  const [draggingThresholdExceeded, setDraggingThresholdExceeded] =
    useState(false);

  const scrollByItem = (direction: "left" | "right") => {
    if (scrollRef.current) {
      const itemWidth =
        scrollRef.current.querySelector(".info")?.clientWidth || 160;
      const gap = 10;
      const totalScrollAmount = itemWidth + gap;
      const newScrollPosition =
        scrollRef.current.scrollLeft +
        (direction === "right" ? totalScrollAmount : -totalScrollAmount);

      scrollRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
    }
  };

  const handleClick = (item: any) => {
    if (!draggingThresholdExceeded) {
      if (item.product_url) {
        window.open(item.product_url, "_blank");
      }
    }
    setDraggingThresholdExceeded(false); // Reset after click
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (scrollRef.current) {
      setIsDragging(true);
      setStartX(e.pageX - scrollRef.current.offsetLeft);
      setScrollLeftX(scrollRef.current.scrollLeft);
      setDraggingThresholdExceeded(false); // Reset threshold on new drag
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging || !scrollRef.current) return;
    e.preventDefault();

    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust scrolling speed

    // Check if the dragging distance exceeds the threshold
    if (Math.abs(walk) > 5) {
      setDraggingThresholdExceeded(true);
    }

    scrollRef.current.scrollLeft = scrollLeftX - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  return (
    <div className="gallery-container">
      <p className="title">{t("unavailable.title")}</p>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div onClick={() => scrollByItem("left")} className="left-arrow">
          <ArrowIcon />
        </div>
        <div
          className="scroll-container"
          ref={scrollRef}
          style={{
            overflowX: "auto",
            whiteSpace: "nowrap",
            display: "inline-flex",
            marginBottom: isMobile ? "30px" : 0,
            cursor: isDragging ? "grabbing" : "grab",
          }}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
        >
          {similarProducts.map((item, index) => (
            <div
              key={index}
              className="info"
              style={{
                minWidth: "160px",
                flex: "0 0 auto",
              }}
              onClick={() => handleClick(item)}
            >
              <img
                src={item.image_url}
                alt={item.title}
                style={{ width: "160px", height: "auto" }}
                draggable="false"
              />
              <p className="info-text">{item.title}</p>
            </div>
          ))}
        </div>
        <div onClick={() => scrollByItem("right")} className="right-arrow">
          <ArrowIcon style={{ transform: "rotate(180deg)" }} />
        </div>
      </div>
    </div>
  );
};

export default UnavailableGallery;
