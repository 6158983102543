import { FC, useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

import MeasuredBy from "../molecules/MeasuredBy";
import {
  activeStylesContinue,
  disabledStylesContinue,
  resultScreenUI,
} from "../../configs";

import {
  CheckCircle as CheckCircleIcon,
  Check as CheckIcon,
} from "@mui/icons-material";

import H_twh from "../../assets/result/H_twh.svg";
import H_ctw from "../../assets/result/H_ctw.svg";
import H_ctwh from "../../assets/result/H_ctwh.svg";
import H_h from "../../assets/result/H_h.svg";
import H_h_unisex from "../../assets/result/H_h_unisex.svg";

import F_c from "../../assets/result/F_c.svg";
import F_cw from "../../assets/result/F_cw.svg";
import F_ctwh from "../../assets/result/F_ctwh.svg";
import F_wh from "../../assets/result/F_wh.svg";
import F_h_unisex from "../../assets/result/F_h_unisex.svg";

import { removeLocalStore, setLocalStore } from "../../store/localStoreUtils";
import { useUserContext } from "../../store/userContext";
import { ReducedResultType, SizeDataType } from "../../types/result";
import { GENDERS } from "../../constants/modal";
import SizeSelector from "./components/SizeSelector";
import { capitalizeFirstLetter } from "../../utils";
import UnavailableGallery from "./components/UnavailableGallery";
import { findSimilarProducts } from "../../api/endpoints";
import { brandsArray } from "../../configs/configLoader";

import "./index.css";

type SelectorSizeType = "unfocused" | "focused" | "unavailable";

interface IPropsResult {
  reducedResult: ReducedResultType;
  selectedGender: string;
  productStockData: any;
  similarProducts: any;
  isSizeUnavailable: boolean;
  selectedSize: SizeDataType | null;
  setSelectedSize: React.Dispatch<React.SetStateAction<SizeDataType | null>>;
  setSimilarProducts: React.Dispatch<any>;
  restart: () => void;
}

const Result: FC<IPropsResult> = ({
  reducedResult,
  selectedGender,
  productStockData,
  similarProducts,
  isSizeUnavailable,
  selectedSize,
  setSelectedSize,
  setSimilarProducts,
  restart,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isSmallMobile = useMediaQuery({ maxWidth: 550 });
  const userContext = useUserContext() || undefined;
  const user = userContext?.user;

  const { t } = useTranslation("components/results/result");

  const fit_feedbacks = [t("size.size1"), t("size.size2"), t("size.size3")];

  const [selectedVariant, setSelectedVariant] = useState<string>("1");
  const [selectedSizeImage, setSelectedSizeImage] = useState<string>("");
  const [feedbacks, setFeedbacks] = useState<any>({});

  const urlParameters = new URLSearchParams(window.location.search);
  const domain = urlParameters.get("domain");

  const brandDefined = brandsArray.find((brand) =>
    domain ? brand.domains.includes(domain) : null
  );

  useEffect(() => {
    if (reducedResult) {
      const entries = Object.entries(reducedResult);
      if (entries.length > 1) {
        setSelectedVariant(entries[1][0]);
      }
    }
  }, [reducedResult]);

  useEffect(() => {
    const handleFindSimilarProducts = async (size: any) => {
      const urlParameters = new URLSearchParams(window.location.search);
      const product_id = urlParameters.get("product_id");
      const domain = urlParameters.get("domain");

      const noVariantIdBrands = [
        "Place des Tendances",
        "Rodier",
        "La Canadienne",
      ];
      const variantIsUnavailable = brandDefined?.name
        ? noVariantIdBrands?.includes(brandDefined?.name)
        : false;

      const variantId = variantIsUnavailable ? null : size?.variant_id;

      if (domain && product_id) {
        const similarProducts = await findSimilarProducts(
          domain,
          product_id,
          variantId
        );
        setSimilarProducts(similarProducts);
      }
    };

    if (selectedVariant && reducedResult) {
      const size = reducedResult[selectedVariant];
      setSelectedSize(size);

      if (isSizeUnavailable) {
        handleFindSimilarProducts(size);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariant, reducedResult, isSizeUnavailable]);

  const findByLabelRank = (rank: number) => {
    if (reducedResult) {
      return Object.values(reducedResult).find(
        (item) => item.label_rank === rank
      );
    }
  };

  const itemWithLabelRank0 = findByLabelRank(0);
  const itemWithLabelRank2 = findByLabelRank(2);

  const getActualDescription = () => {
    if (selectedVariant) {
      switch (selectedVariant) {
        case "0":
          return selectedSize?.possible === 0
            ? "description.size_down.not_possible"
            : "description.size_down.ideal";
        case "1":
          return "description.normal.ideal";
        case "2":
          return selectedSize?.possible === 0
            ? "description.size_up.not_possible"
            : "description.size_up.ideal";
        default:
          return "description.normal.ideal";
      }
    } else {
      return "description.normal.ideal";
    }
  };

  useEffect(() => {
    const focused = selectedVariant ? parseInt(selectedVariant, 10) : null;
    if (reducedResult && focused !== null) {
      const focusedFeeback = reducedResult[focused].fit_indicators;
      const result = focusedFeeback.reduce((acc: any, { limb, value }: any) => {
        acc[limb] = value;
        return acc;
      }, {});
      setFeedbacks(result);
    }
  }, [reducedResult, selectedVariant]);

  useEffect(() => {
    if (feedbacks) {
      if (selectedGender === GENDERS.M) {
        // result 1 - MALE
        if (
          feedbacks.hip > 0 &&
          feedbacks.waist > 0 &&
          feedbacks.chest === undefined
        ) {
          setSelectedSizeImage(H_twh);
        }

        // result 2 - MALE
        if (
          feedbacks.chest > 0 &&
          feedbacks.waist > 0 &&
          feedbacks.hip === undefined
        ) {
          setSelectedSizeImage(H_ctw);
        }
      }

      if (selectedGender === GENDERS.F) {
        // result 1 - FEMALE
        if (
          feedbacks.chest > 0 &&
          feedbacks.waist > 0 &&
          feedbacks.hip === undefined
        ) {
          setSelectedSizeImage(F_cw);
        }

        // result 2 - FEMALE
        if (
          feedbacks.hip > 0 &&
          feedbacks.waist > 0 &&
          feedbacks.chest === undefined
        ) {
          setSelectedSizeImage(F_wh);
        }
      }

      // result 3 - MALE
      if (
        feedbacks.waist > 0 &&
        feedbacks.hip === undefined &&
        feedbacks.chest === undefined
      ) {
        setSelectedSizeImage(H_h);
      }

      // result 3 - FEMALE
      if (
        feedbacks.chest > 0 &&
        feedbacks.waist === undefined &&
        feedbacks.hip === undefined
      ) {
        setSelectedSizeImage(F_c);
      }

      // unisex result
      if (
        feedbacks.hip > 0 &&
        feedbacks.waist === undefined &&
        feedbacks.chest === undefined
      ) {
        setSelectedSizeImage(
          selectedGender === GENDERS.M ? H_h_unisex : F_h_unisex
        );
      }

      // result 3 - ALL
      if (feedbacks.chest > 0 && feedbacks.waist > 0 && feedbacks.hip > 0) {
        setSelectedSizeImage(selectedGender === GENDERS.M ? H_ctwh : F_ctwh);
      }
    } else {
      // setSelectedSizeImage(selectedGender === GENDERS.F ? F_cw : H_h);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbacks, selectedGender]);

  const getSelectorSizeStyles = (type: SelectorSizeType, variant: string) => {
    const sizeStyles = resultScreenUI.sizeSelector[type]!;

    const selectorSizeStyles = {
      backgroundColor:
        sizeStyles.backgroundColor as React.CSSProperties["backgroundColor"],
      fontWeight: sizeStyles.fontWeight as React.CSSProperties["fontWeight"],
      fontSize: sizeStyles.fontSize as React.CSSProperties["fontSize"],
      textTransform:
        sizeStyles.textTransform as React.CSSProperties["textTransform"],
      border: `${sizeStyles.borderWidth} solid ${sizeStyles.borderColor}`,
      color: sizeStyles.fontColor as React.CSSProperties["color"],
      borderTopLeftRadius:
        variant === "0"
          ? (sizeStyles.borderRadius as React.CSSProperties["borderRadius"])
          : 0,
      borderBottomLeftRadius:
        variant === "0"
          ? (sizeStyles.borderRadius as React.CSSProperties["borderRadius"])
          : 0,
      borderTopRightRadius:
        variant === "2"
          ? (sizeStyles.borderRadius as React.CSSProperties["borderRadius"])
          : 0,
      borderBottomRightRadius:
        variant === "2"
          ? (sizeStyles.borderRadius as React.CSSProperties["borderRadius"])
          : 0,
    };

    return selectorSizeStyles;
  };

  const firstSelectorStyles =
    selectedVariant === "0"
      ? getSelectorSizeStyles(
          selectedSize?.possible === 0 && !isSizeUnavailable
            ? "unavailable"
            : "focused",
          "0"
        )
      : getSelectorSizeStyles("unfocused", "0");

  const secondSelectorStyle =
    selectedVariant === "1"
      ? getSelectorSizeStyles("focused", "1")
      : getSelectorSizeStyles("unfocused", "1");

  const thirdSelectorStyle =
    selectedVariant === "2"
      ? getSelectorSizeStyles(
          selectedSize?.possible === 0 && !isSizeUnavailable
            ? "unavailable"
            : "focused",
          "2"
        )
      : getSelectorSizeStyles("unfocused", "2");

  const skipTextTransform = resultScreenUI.restartCTA
    .textTransform as React.CSSProperties["textTransform"];

  const submitButtonStyles = !selectedSize?.variant_id
    ? disabledStylesContinue
    : activeStylesContinue;

  const sendMid = () => {
    window.parent.postMessage(
      { data: "mid", mid: localStorage.getItem("mid") },
      "*"
    );
  };

  const clearMid = () => {
    window.parent.postMessage({ action: "clearMid" }, "*");
  };

  const addToCart = () => {
    window.parent.postMessage(
      {
        action: "addToCart",
        variantId: selectedSize?.variant_id,
      },
      "*"
    );
  };

  const closeIframe = () =>
    window.parent.postMessage({ action: "closeIframe" }, "*");

  return (
    <div className="result">
      <div className="size">
        <h1
          style={{
            color: isSizeUnavailable
              ? (resultScreenUI.size_text
                  .fontColor as React.CSSProperties["color"])
              : (resultScreenUI.recommendedSize
                  .fontColor as React.CSSProperties["color"]),
            fontSize:
              brandDefined?.name !== "Lacoste" && isMobile
                ? "40px"
                : (resultScreenUI.recommendedSize
                    .fontSize as React.CSSProperties["fontSize"]),
            fontWeight: resultScreenUI.recommendedSize
              .fontWeight as React.CSSProperties["fontWeight"],
            textAlign: resultScreenUI.recommendedSize
              .textAlign as React.CSSProperties["textAlign"],
            // minHeight: isMobile ? "70px" : "90px",
            margin: "20px 0 ",
          }}
        >
          {selectedSize?.label || ""}
        </h1>
        <div className="size-name">
          {selectedVariant === "1" && (
            <>
              <h2
                style={{
                  color: resultScreenUI.subtitles
                    .fontColor as React.CSSProperties["color"],
                  fontSize: resultScreenUI.subtitles
                    .fontSize as React.CSSProperties["fontSize"],
                  fontWeight: resultScreenUI.subtitles
                    .fontWeight as React.CSSProperties["fontWeight"],
                  textAlign: resultScreenUI.subtitles
                    .textAlign as React.CSSProperties["textAlign"],
                }}
              >
                {t("size.title")}
              </h2>
              <CheckCircleIcon
                style={{
                  color: resultScreenUI.subtitles
                    .fontColor as React.CSSProperties["color"],
                }}
              />
            </>
          )}
        </div>
        <div className="sizes-menu">
          <div
            className={`menu-item ${
              itemWithLabelRank0?.variant_id &&
              selectedVariant === "0" &&
              "active"
            }`}
            onClick={() =>
              itemWithLabelRank0?.variant_id && setSelectedVariant("0")
            }
            style={{
              ...firstSelectorStyles,
              cursor: !itemWithLabelRank0?.variant_id ? "default" : "pointer",
              textTransform:
                firstSelectorStyles?.textTransform === "capitalize"
                  ? "none"
                  : firstSelectorStyles?.textTransform,
            }}
          >
            <p>
              {firstSelectorStyles?.textTransform === "capitalize"
                ? capitalizeFirstLetter(
                    itemWithLabelRank0?.variant_id ? fit_feedbacks[0] : ""
                  )
                : itemWithLabelRank0?.variant_id
                ? fit_feedbacks[0]
                : ""}
            </p>
          </div>
          <div
            className={`menu-item ${selectedVariant === "1" && "active"}`}
            onClick={() => setSelectedVariant("1")}
            style={{
              ...secondSelectorStyle,
              cursor: "pointer",
              textTransform:
                secondSelectorStyle?.textTransform === "capitalize"
                  ? "none"
                  : secondSelectorStyle?.textTransform,
            }}
          >
            <p>
              {secondSelectorStyle?.textTransform === "capitalize"
                ? capitalizeFirstLetter(fit_feedbacks[1])
                : fit_feedbacks[1]}
            </p>
          </div>
          <div
            className={`menu-item ${
              itemWithLabelRank2?.variant_id &&
              selectedVariant === "2" &&
              "active"
            }`}
            onClick={() =>
              itemWithLabelRank2?.variant_id && setSelectedVariant("2")
            }
            style={{
              ...thirdSelectorStyle,
              cursor: !itemWithLabelRank2?.variant_id ? "default" : "pointer",
              textTransform:
                thirdSelectorStyle?.textTransform === "capitalize"
                  ? "none"
                  : thirdSelectorStyle?.textTransform,
            }}
          >
            <p>
              {thirdSelectorStyle?.textTransform === "capitalize"
                ? capitalizeFirstLetter(
                    itemWithLabelRank2?.variant_id ? fit_feedbacks[2] : ""
                  )
                : itemWithLabelRank2?.variant_id
                ? fit_feedbacks[2]
                : ""}
            </p>
          </div>
        </div>
      </div>
      {resultScreenUI.generateResult ? (
        <div className="result-body">
          <div className="result">
            <SizeSelector
              selectedSizeImage={selectedSizeImage}
              feedbacks={feedbacks}
            />
          </div>
          <div className="text">
            {!isSmallMobile && (
              <div className="result-title">
                <CheckIcon />
                <p>
                  {selectedVariant
                    ? fit_feedbacks[parseInt(selectedVariant, 10)]
                    : fit_feedbacks[1]}
                </p>
              </div>
            )}
            <p
              className="result-description"
              dangerouslySetInnerHTML={{
                __html: t(getActualDescription()).replace(
                  "[S]",
                  `<b>${selectedSize?.label || ""}</b>`
                ),
              }}
            />
          </div>
        </div>
      ) : (
        <div
          className="result-body-text"
          style={{
            borderBottom: isSizeUnavailable ? "1px solid #E9E9E9" : "none",
            paddingBottom: isSizeUnavailable ? "25px" : 0,
            justifyContent: isSizeUnavailable
              ? "left"
              : (resultScreenUI.Description
                  .textAlign as React.CSSProperties["justifyContent"]),
          }}
        >
          {isSizeUnavailable ? (
            <p
              style={{
                color: resultScreenUI.Description
                  .fontColor as React.CSSProperties["color"],
                fontSize: resultScreenUI.Description
                  .fontSize as React.CSSProperties["fontSize"],
                fontWeight: resultScreenUI.Description
                  .fontWeight as React.CSSProperties["fontWeight"],
                textAlign: resultScreenUI.Description
                  .textAlign as React.CSSProperties["textAlign"],
                display: "flex",
                alignItems: "center",
                lineHeight: "20px",
              }}
            >
              {t("unavailable.description")}
            </p>
          ) : (
            <div
              style={{
                color: resultScreenUI.Description
                  .fontColor as React.CSSProperties["color"],
                fontSize: resultScreenUI.Description
                  .fontSize as React.CSSProperties["fontSize"],
                fontWeight: resultScreenUI.Description
                  .fontWeight as React.CSSProperties["fontWeight"],
                textAlign: resultScreenUI.Description
                  .textAlign as React.CSSProperties["textAlign"],
                lineHeight: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {t(getActualDescription()).replace(
                "[S]",
                selectedSize?.label || ""
              )}
            </div>
          )}
        </div>
      )}
      {isSizeUnavailable && similarProducts?.length ? (
        <UnavailableGallery similarProducts={similarProducts} />
      ) : null}
      {!isSizeUnavailable && (
        <button
          type="button"
          className="continue-button step-bottom-result"
          onClick={() => {
            if (selectedSize?.variant_id) {
              addToCart();
              sendMid();
              closeIframe();
            }
          }}
          style={{
            ...submitButtonStyles,
            textTransform:
              submitButtonStyles.textTransform === "capitalize"
                ? "none"
                : submitButtonStyles.textTransform,
          }}
        >
          {submitButtonStyles.textTransform === "capitalize"
            ? capitalizeFirstLetter(
                t("button").replace("[S]", selectedSize?.label || "")
              )
            : t("button").replace("[S]", selectedSize?.label || "")}
        </button>
      )}
      <span
        className="skip"
        onClick={() => {
          clearMid();
          restart();

          removeLocalStore("mid");
          removeLocalStore("user");
          removeLocalStore("productSize");
          removeLocalStore("gender");

          const urlParams = new URLSearchParams(window.location.search);
          removeLocalStore(`${urlParams.get("pid")}`);

          if (localStorage.getItem("user")) {
            setLocalStore("user", JSON.stringify(user));
          }
        }}
        style={{
          backgroundColor:
            brandDefined?.name === "SRP"
              ? "transparent"
              : (resultScreenUI.restartCTA
                  .backgroundColor as React.CSSProperties["backgroundColor"]),
          fontWeight: resultScreenUI.restartCTA
            .fontWeight as React.CSSProperties["fontWeight"],
          fontSize: resultScreenUI.restartCTA
            .fontSize as React.CSSProperties["fontSize"],
          color: resultScreenUI.restartCTA
            .fontColor as React.CSSProperties["color"],
          textTransform:
            skipTextTransform === "capitalize" ? "none" : skipTextTransform,
          borderRadius: resultScreenUI.restartCTA
            .borderRadius as React.CSSProperties["borderRadius"],
          borderColor: resultScreenUI.restartCTA
            .borderColor as React.CSSProperties["borderColor"],
          borderWidth: resultScreenUI.restartCTA
            .borderWidth as React.CSSProperties["borderWidth"],
        }}
      >
        {skipTextTransform === "capitalize"
          ? capitalizeFirstLetter(t("skip"))
          : t("skip")}
      </span>
      <MeasuredBy />
    </div>
  );
};

export default Result;
